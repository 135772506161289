import Model from './Model';
import { Type } from './Type';

export class Types extends Model {
  static createTypes (data) {
    try {
      return Array.isArray(data.choices) ? data.choices.map(type => (Type.createType(type))) : [];
    } catch (e) {
      console.error(e);
    }
  }
}
