import Model from './Model';

export class PoliciesSummary extends Model {
  static createPoliciesSummary (totalIACPercent, totalIACPercentCalc, totalIAC, totalFundValue) {
    try {
      const policiesSummary = new PoliciesSummary();
      policiesSummary.set('totalIAC', totalIAC ?? '');
      policiesSummary.set('totalIACPercent', totalIACPercent ?? '');
      policiesSummary.set('totalIACPercentCalc', totalIACPercentCalc ?? 0);
      policiesSummary.set('totalFundValue', totalFundValue ?? null);
      return policiesSummary;
    } catch (e) {
      console.log(e);
    }
  }
}
