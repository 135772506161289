import Model from './Model';

export class ClientData extends Model {
  static createClientData (clientDetails, partnerDetails, clientPolicies) {
    try {
      const clientData = new ClientData();
      clientData.set('clientDetails', clientDetails ?? null);
      clientData.set('partnerDetails', partnerDetails ?? null);
      clientData.set('policiesDetails', clientPolicies ?? null);
      return clientData;
    } catch (e) {
      console.log(e);
    }
  }
}
