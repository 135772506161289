import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { InternalAndExternal } from '../Enums/InternalAndExternalEnum';
import { RecommendationType } from '../Enums/RecommendationTypeEnum';
import { mathRound } from '../Utils/IACUtils';

const PER_MONTH = '\u00a0pm';

function FundValue ({ item, setInternalExternalPolicyValues }) {
  const [externalValue, setExternalValue] = useState(item.fundValue);

  const onExternalValueChange = (item, value) => {
    if (item.internalPolicyValue - Number(value) >= 0) {
      setExternalValue(value);
      const internalPolicyValue = mathRound(item.internalPolicyValue - Number(value));
      const externalPolicyValue = mathRound(Number(value));
      setInternalExternalPolicyValues(item.internalPolicyKey, item.key, internalPolicyValue, externalPolicyValue);
    }
  };

  if (item.internalAndExternal === InternalAndExternal.INTERNAL) {
    return (
      <div className="display-inline">
        <Form.Control
          type="number"
          value={item.fundValue}
          className="value-input"
          readOnly
        />
      </div>
    );
  } else if (item.internalAndExternal === InternalAndExternal.EXTERNAL) {
    return (
      <div className="display-inline">
        <Form.Control
          type="number"
          placeholder="Enter Value"
          value={externalValue}
          className="value-input"
          onChange={e => onExternalValueChange(item, e.target.value)}
        />
      </div>

    );
  } else if (item.recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
    return item.initialPremiumValue + PER_MONTH;
  }
  return item.fundValue;
}

FundValue.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    index: PropTypes.number,
    fundOrPolicyNum: PropTypes.string,
    fundValue: PropTypes.number,
    inceptionDate: PropTypes.string,
    pensionType: PropTypes.string,
    policyStatus: PropTypes.string,
    productName: PropTypes.string,
    recommendationType: PropTypes.string,
    productProvider: PropTypes.string,
    topUp: PropTypes.string,
    type: PropTypes.string,
    sourceOfFunds: PropTypes.string,
    iacPercent: PropTypes.string,
    iacValue: PropTypes.string,
    dataGroup: PropTypes.string,
    totalIACPercent: PropTypes.string,
    totalIAC: PropTypes.string,
    internalAndExternal: PropTypes.oneOf([InternalAndExternal.INTERNAL, InternalAndExternal.EXTERNAL]),
    internalPolicyKey: PropTypes.string,
    internalPolicyValue: PropTypes.number
  }),
  setInternalExternalPolicyValues: PropTypes.func.isRequired
};

export default FundValue;
