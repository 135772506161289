import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Messages ({ notifications }) {
  const renderMessage = (item) => {
    return (
      <ListGroupItem>{item}</ListGroupItem>
    );
  };

  if (notifications && Array.isArray(notifications) && notifications.length > 0) {
    return (
      <>
        <ListGroup>
          {notifications.map(item => renderMessage(item.text))}
        </ListGroup>
      </>
    );
  }

  return null;
}

Messages.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.string)
};

export default Messages;
