import Model from './Model';

export class IACValues extends Model {
  static createIacValues (iacPercent, iacValue, isAdditionalIacCalcNeeded) {
    try {
      const iacValues = new IACValues();
      iacValues.set('iacPercent', getIacPercent(iacPercent));
      iacValues.set('iacValue', getIacValue(iacValue));
      iacValues.set('isAdditionalIacCalcNeeded', isAdditionalIacCalcNeeded);
      return iacValues;
    } catch (e) {
      console.log(e);
    }
  }
}

const getIacPercent = (iacPercent) => {
  if (iacPercent === 'NCC') {
    return iacPercent;
  } else if (iacPercent || iacPercent === 0) {
    const iacPercentStr = iacPercent + '';
    const decimalPos = iacPercentStr.indexOf('.');
    if (decimalPos === -1) {
      return iacPercentStr + '.00%';
    } else {
      if (decimalPos === iacPercentStr.length - 2) {
        // after some arithmetic operation result can be like that DD.D
        return iacPercentStr + '0%';
      } else {
        return iacPercentStr + '%';
      }
    }
  } else {
    return '';
  }
};

const getIacValue = (iacValue) => {
  if (iacValue || iacValue === 0) {
    const iacValueStr = iacValue + '';
    const decimalPos = iacValueStr.indexOf('.');
    if (decimalPos === -1) {
      return iacValueStr + '.00';
    } else {
      if (decimalPos === iacValueStr.length - 2) {
        // after some arithmetic operation result can be like that DD.D
        return iacValueStr + '0';
      } else {
        return iacValueStr;
      }
    }
  } else {
    return '';
  }
};
