import { ClientOACstatus } from '../Enums/ClientOACstatusEnum';
import { Frequency } from '../Enums/FrequencyEnum';
import { DataGroup } from '../Enums/DataGroupEnum';
import { PostCalculationTargetFieldName } from '../Enums/PostCalculationTargetFieldNameEnum';
import { RecommendationType } from '../Enums/RecommendationTypeEnum';
import { IACValues } from '../Transforms/IACValues';
import { PostCalculation } from '../Transforms/PostCalculation';
import { InternalAndExternal } from '../Enums/InternalAndExternalEnum';

const iacValueLookup = (oacStatus, recommendationType, sourceOfFunds, fundValue, sourceOfFundsEnum) => {
  switch (oacStatus) {
    case ClientOACstatus.NON_OAC :
      return nonOacLookup(recommendationType, sourceOfFunds, fundValue, sourceOfFundsEnum);
    case ClientOACstatus.OAC :
      return oacLookup(recommendationType, sourceOfFunds, fundValue, sourceOfFundsEnum);
  }
};

const nonOacLookup = (recommendationType, sourceOfFunds, fundValue, sourceOfFundsEnum) => {
  switch (recommendationType) {
    case RecommendationType.ANNUITY :
    case RecommendationType.REGULAR_UFPLS_TERM :
    case RecommendationType.REGULAR_UFPLS :
    case RecommendationType.DEPENDENT_NOPMINEE_ANNUITY :
    case RecommendationType.FULL_DRAWDOWN :
    case RecommendationType.PART_DRAWDOWN :
    case RecommendationType.PART_UFPLS :
      return IACValues.createIacValues('', '', true);
    case RecommendationType.BOND :
    case RecommendationType.OEIC :
    case RecommendationType.PENSION_SWITCH :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[0] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[2] :
          break;
      }
      break;
    case RecommendationType.PENSION_SWITCH_IMMEDIATE_VESTING :
      return IACValues.createIacValues('', '', true);
    case RecommendationType.FULL_UFPLS :
      return lookupValue2();
    case RecommendationType.ISA_INCL_IAC :
    case RecommendationType.ISA_NOT_INCL_IAC :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[0] :
        case sourceOfFundsEnum.general[2] :
          return lookupValuePercent(1, fundValue);
      }
      break;
    case RecommendationType.REGULAR_PREMIUM_GROSS :
      return lookupValuePercent(3, fundValue);
    case RecommendationType.SECONDARY_PENSION_BENEFIT :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('0.00', '0.00', false);
        case sourceOfFundsEnum.general[0] :
        case sourceOfFundsEnum.general[2] :
          return IACValues.createIacValues('', '', true);
      }
      break;
    case RecommendationType.SINGLE_PREMIUM_PENSION :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
        case sourceOfFundsEnum.general[0] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[2] :
          break;
      }
      break;
    default:
      return IACValues.createIacValues('0.00', '0.00', false);
  }

  return IACValues.createIacValues('0.00', '0.00', false);
};

const oacLookup = (recommendationType, sourceOfFunds, fundValue, sourceOfFundsEnum) => {
  switch (recommendationType) {
    case RecommendationType.ANNUITY :
    case RecommendationType.REGULAR_UFPLS_TERM :
    case RecommendationType.REGULAR_UFPLS :
    case RecommendationType.FULL_DRAWDOWN :
    case RecommendationType.PART_DRAWDOWN :
    case RecommendationType.PART_UFPLS :
      return IACValues.createIacValues('', '', true);
    case RecommendationType.DEPENDENT_NOPMINEE_ANNUITY :
      return lookupValuePercent(1, fundValue);
    case RecommendationType.BOND :
    case RecommendationType.OEIC :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[0] :
          return lookupValuePercent(0, fundValue);
        case sourceOfFundsEnum.general[2] :
          break;
      }
      break;
    case RecommendationType.PENSION_SWITCH :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[0] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[2] :
          break;
      }
      break;
    case RecommendationType.PENSION_SWITCH_IMMEDIATE_VESTING :
      return IACValues.createIacValues('', '', true);
    case RecommendationType.FULL_UFPLS :
    case RecommendationType.ISA_INCL_IAC :
    case RecommendationType.ISA_NOT_INCL_IAC :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[0] :
        case sourceOfFundsEnum.general[2] :
          return lookupValuePercent(0, fundValue);
      }
      break;
    case RecommendationType.REGULAR_PREMIUM_GROSS :
      return lookupValuePercent(3, fundValue);
    case RecommendationType.SECONDARY_PENSION_BENEFIT :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
          return IACValues.createIacValues('0.00', '0.00');
        case sourceOfFundsEnum.general[0] :
        case sourceOfFundsEnum.general[2] :
          return lookupValuePercent(1, fundValue);
      }
      break;
    case RecommendationType.SINGLE_PREMIUM_PENSION :
      switch (sourceOfFunds) {
        case sourceOfFundsEnum.general[1] :
        case sourceOfFundsEnum.general[0] :
          return IACValues.createIacValues('', '', true);
        case sourceOfFundsEnum.general[2] :
          break;
      }
      break;
    default:
      return IACValues.createIacValues('0.00', '0.00', false);
  }

  return IACValues.createIacValues('0.00', '0.00', false);
};

const lookupValue1 = (fundValue, tiers) => {
  let iacValue = 0;
  if (tiers) {
    if (fundValue <= tiers.limits[0]) {
      iacValue = (fundValue * tiers.percents[0]);
    } else if (fundValue > tiers.limits[0] && fundValue <= tiers.limits[1]) {
      iacValue = (tiers.charges[0] + ((fundValue - tiers.limits[0]) * tiers.percents[1]));
    } else if (fundValue > tiers.limits[1] && fundValue <= tiers.limits[2]) {
      iacValue = (tiers.charges[0] + tiers.charges[1] +
          ((fundValue - tiers.limits[1]) * tiers.percents[2]));
    } else if (fundValue > tiers.limits[2] && fundValue <= tiers.limits[3]) {
      iacValue = (tiers.charges[0] + tiers.charges[1] + tiers.charges[2] +
          ((fundValue - tiers.limits[2]) * tiers.percents[3]));
    } else if (fundValue > tiers.limits[3]) {
      iacValue = (tiers.charges[0] + tiers.charges[1] + tiers.charges[2] + tiers.charges[3] +
          ((fundValue - tiers.limits[3]) * tiers.percents[4]));
    }
  }

  const iacPercent = roundDown(((iacValue / fundValue) * 100).toPrecision(12), 2).toFixed(2);

  return IACValues.createIacValues(
    (iacValue === 0) || (fundValue === 0) ? '0.00' : iacPercent,
    roundDown(iacValue, 2));
};

const lookupValue2 = () => {
  return IACValues.createIacValues('NCC', (420).toFixed(2));
};

const lookupValuePercent = (iacPercent, fundValue) => {
  const iacValue = fundValue * (iacPercent / 100);
  return IACValues.createIacValues(
    roundDown(iacPercent, 2), roundDown(iacValue, 2));
};

const multiplyByFrequency = (value, frequency) => {
  switch (frequency) {
    case Frequency.MONTHLY:
      return Number(value) * 12;
    case Frequency.QUARTERLY:
      return Number(value) * 4;
    case Frequency.HALF_YEARLY:
      return Number(value) * 2;
    case Frequency.ANNUALLY:
      return Number(value);
    default:
      return 0;
  }
};

const roundDown = (value, unit) => {
  value = value.toString();
  if (value.indexOf('.') > 0) {
    value = value.slice(0, value.indexOf('.') + unit + 1);
  }
  return Number(value);
};

const mathRound = (value) => {
  return Math.round(value * 100) / 100;
};

const mathFloor = (value) => {
  return Math.floor(value * 100) / 100;
};

const mathCeil = (value) => {
  return Math.ceil(value * 100) / 100;
};

const calculateIacForMultipleRecommendations = (policies, configurationData) => {
  let fundValueSum = 0;

  policies.forEach(policy => {
    if (policy.isAdditionalIacCalcNeeded) {
      fundValueSum += policy.fundValue;
    }
  });

  fundValueSum = Math.round(fundValueSum * 100) / 100;

  const iacValues = lookupValue1(fundValueSum, configurationData.tiers);

  policies.forEach(policy => {
    if (policy.isAdditionalIacCalcNeeded) {
      if (policy.recommendationType === RecommendationType.ISA_NOT_INCL_IAC) {
        policy.iacValue = iacValues.iacValue ? calculateNetIsaIacValue(policy.fundValue, iacValues) : 0;
      } else {
        policy.iacValue = iacValues.iacValue ? splitIacValue(policy.fundValue, fundValueSum, iacValues) : 0;
      }
      policy.iacPercent = iacValues.iacPercent;
    }
  });
};

const splitIacValue = (fundValue, fundValueSum, iacValues) => {
  return roundDown(iacValues.iacValue ? parseFloat(iacValues.iacPercent.replace('%', '')) / 100 * fundValue : 0, 2).toFixed(2);
};

const calculateNetIsaIacValue = (fundValue, iacValues) => {
  return roundDown(iacValues.iacValue ? parseFloat(iacValues.iacPercent.replace('%', '')) / 100 * fundValue / (1 - parseFloat(iacValues.iacPercent.replace('%', '')) / 100) : 0, 2).toFixed(2);
};

const calculateFundValueBasedOnIndexation = (premium, indexation) => {
  return roundDown(premium * 12 + premium * 12 * (1 + indexation) + premium * 12 * Math.pow((1 + indexation), 2) +
    premium * 12 * Math.pow((1 + indexation), 3) + premium * 12 * Math.pow((1 + indexation), 4), 2);
};

const getPostCalculationFields = (dataGroup, recommendationType, fields) => {
  switch (dataGroup) {
    case DataGroup.FUND_ZA:
      return getPostCalculationFieldsFundZa(recommendationType, fields);
    case DataGroup.ANNUITY:
      return getPostCalculationFieldsAnnuity(recommendationType, fields);
    case DataGroup.ASSET:
      return getPostCalculationFieldsAsset(recommendationType, fields);
    default :
      return [];
  }
};

const getPostCalculationFieldsFundZa = (recommendationType, fields) => {
  const postCalculationFields = [];
  switch (recommendationType) {
    case RecommendationType.PENSION_SWITCH:
    case RecommendationType.PENSION_SWITCH_IMMEDIATE_VESTING:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.trnsfbc, PostCalculationTargetFieldName.TRANSFER_AFTER_CHARGES));
      break;
    case RecommendationType.REGULAR_PREMIUM_GROSS:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.lump_sum_top_up, PostCalculationTargetFieldName.EMPLOYEE_GROSS_AMOUNT_POST_IAC));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.employer_regular_contribution, PostCalculationTargetFieldName.EMPLOYER_REGULAR_CONTRIBUTION_POST_IAC));
      break;
    case RecommendationType.SINGLE_PREMIUM_PENSION:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.employee_single_gross_contribution, PostCalculationTargetFieldName.EMPLOYEE_SINGLE_GROSS_CONTRIBUTION_POST_IAC));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.employer_single_contribution, PostCalculationTargetFieldName.EMPLOYER_SINGLE_CONTRIBUTION_POST_IAC));
      break;
  }
  return postCalculationFields;
};

const getPostCalculationFieldsAnnuity = (recommendationType, fields) => {
  const postCalculationFields = [];
  switch (recommendationType) {
    case RecommendationType.FULL_DRAWDOWN:
    case RecommendationType.PART_DRAWDOWN:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.uncrystallised_tfr_amount, PostCalculationTargetFieldName.NET_UNCRYSTALLISED_TFR_AMOUNT));
      break;
    case RecommendationType.ANNUITY:
    case RecommendationType.SECONDARY_PENSION_BENEFIT:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.gross_pension_fund, PostCalculationTargetFieldName.INITIAL_AMOUNT));
      break;
    case RecommendationType.DEPENDENT_NOPMINEE_ANNUITY:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.uncrystallised_tfr_amount, PostCalculationTargetFieldName.NET_UNCRYSTALLISED_TFR_AMOUNT));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.gross_pension_fund, PostCalculationTargetFieldName.INITIAL_AMOUNT));
      break;
    case RecommendationType.REGULAR_UFPLS_TERM:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.reg_ufpls_bc, PostCalculationTargetFieldName.REG_UFPLS_AC));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.ap_initial, PostCalculationTargetFieldName.AP_AC));
      break;
  }
  return postCalculationFields;
};

const getPostCalculationFieldsAsset = (recommendationType, fields) => {
  const postCalculationFields = [];
  switch (recommendationType) {
    case RecommendationType.ISA_INCL_IAC:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.lump_sum_top_up, PostCalculationTargetFieldName.SINGLE_CONTRIBUTION_POST_IAC));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.transfer_before_charges, PostCalculationTargetFieldName.TRANSFER_AFTER_CHARGES));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.b2b_single_before, PostCalculationTargetFieldName.B_2_B_SINGLE_POST));
      break;
    case RecommendationType.ISA_NOT_INCL_IAC:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.single_contribution_post_iac, PostCalculationTargetFieldName.LUMP_SUM_TOP_UP));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.transfer_after_charges, PostCalculationTargetFieldName.TRANSFER_BEFORE_CHARGES));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.b2b_single_post, PostCalculationTargetFieldName.B_2_B_SINGLE_BEFORE));
      break;
    case RecommendationType.REGULAR_PREMIUM_GROSS:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.regular_contribution_before_iac, PostCalculationTargetFieldName.REGULAR_CONTRIBUTION_POST_IAC));
      break;
    case RecommendationType.BOND:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.lump_sum_top_up, PostCalculationTargetFieldName.SINGLE_CONTRIBUTION_POST_IAC));
      break;
    case RecommendationType.OEIC:
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.lump_sum_top_up, PostCalculationTargetFieldName.SINGLE_CONTRIBUTION_POST_IAC));
      postCalculationFields.push(PostCalculation.createPostCalculation(fields.mig_bc, PostCalculationTargetFieldName.MIG_AC));
      break;
  }
  return postCalculationFields;
};

const getInternalExternalProportions = (policy, policies) => {
  if (policy.internalAndExternal === InternalAndExternal.INTERNAL) {
    const externalPolicy = policies.find(item => item.internalPolicyKey === policy.key);
    return policy.fundValue / (policy.fundValue + externalPolicy.fundValue);
  } else if (policy.internalAndExternal === InternalAndExternal.EXTERNAL) {
    return policy.fundValue / policy.internalPolicyValue;
  } else {
    return 1;
  }
};

const calculatePostCalculation = (policies, isBelowTotalOacLimit, totalIACPercentCalc) => {
  policies.forEach(policy => {
    switch (policy.dataGroup) {
      case DataGroup.FUND_ZA:
        calculatePostCalculationFundZa(policy, isBelowTotalOacLimit, getInternalExternalProportions(policy, policies), totalIACPercentCalc);
        break;
      case DataGroup.ANNUITY:
        calculatePostCalculationAnnuity(policy, isBelowTotalOacLimit, getInternalExternalProportions(policy, policies), totalIACPercentCalc);
        break;
      case DataGroup.ASSET:
        calculatePostCalculationAsset(policy, isBelowTotalOacLimit, getInternalExternalProportions(policy, policies), totalIACPercentCalc);
        break;
    }
  });
};

const calculatePostCalculationAnnuity = (policy, isBelowTotalOacLimit, internalExternalProportions, totalIACPercentCalc) => {
  if (Array.isArray(policy.postCalculation) && policy.postCalculation.length > 0) {
    const postCalculationResult = [];
    policy.postCalculation.forEach(item => {
      if (item.sourceValue) {
        const newItem = Object.assign({}, item);
        if (isBelowTotalOacLimit) {
          switch (item.targetFieldName) {
            case PostCalculationTargetFieldName.NET_UNCRYSTALLISED_TFR_AMOUNT:
              newItem.targetValue = policy.fundValue
                ? mathFloor((item.sourceValue - policy.uncrystallisedPcls - policy.iacValue * (item.sourceValue / policy.fundValue)) * internalExternalProportions)
                : 0;
              break;
            case PostCalculationTargetFieldName.INITIAL_AMOUNT:
              newItem.targetValue = policy.fundValue
                ? mathFloor((item.sourceValue - policy.pcls - policy.iacValue * (item.sourceValue / policy.fundValue)) * internalExternalProportions)
                : 0;
              break;
            case PostCalculationTargetFieldName.REG_UFPLS_AC:
            case PostCalculationTargetFieldName.AP_AC:
              newItem.targetValue = policy.fundValue
                ? mathFloor((item.sourceValue - item.sourceValue * (policy.iacValue / policy.fundValue)) * internalExternalProportions)
                : 0;
              break;
          }
        } else {
          switch (item.targetFieldName) {
            case PostCalculationTargetFieldName.REG_UFPLS_AC:
              newItem.targetValue = mathFloor(
                item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100) * internalExternalProportions);
              break;
            case PostCalculationTargetFieldName.NET_UNCRYSTALLISED_TFR_AMOUNT:
              if (item.sourceValue !== 0) {
                newItem.targetValue = mathFloor(
                  (item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100) - policy.uncrystallisedPcls) * internalExternalProportions);
              } else newItem.targetValue = 0;
              break;
            case PostCalculationTargetFieldName.INITIAL_AMOUNT:
              if (item.sourceValue !== 0) {
                newItem.targetValue = mathFloor(
                  (item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100) - policy.pcls) * internalExternalProportions);
              } else newItem.targetValue = 0;
              break;
            case PostCalculationTargetFieldName.AP_AC:
              newItem.targetValue = mathFloor(
                item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100) * internalExternalProportions);
              break;
          }
        }
        postCalculationResult.push(newItem);
      }
    });
    policy.postCalculation = postCalculationResult;
  }
};

const calculatePostCalculationFundZa = (policy, isBelowTotalOacLimit, internalExternalProportions, totalIACPercentCalc) => {
  if (Array.isArray(policy.postCalculation) && policy.postCalculation.length > 0) {
    const postCalculationResult = [];
    policy.postCalculation.forEach(item => {
      if (item.sourceValue) {
        const newItem = Object.assign({}, item);
        if (isBelowTotalOacLimit) {
          switch (item.targetFieldName) {
            case PostCalculationTargetFieldName.TRANSFER_AFTER_CHARGES:
              newItem.targetValue = roundDown((item.sourceValue * internalExternalProportions) - policy.iacValue, 2);
              break;
            case PostCalculationTargetFieldName.EMPLOYEE_GROSS_AMOUNT_POST_IAC:
              newItem.targetValue = roundDown(
                (item.sourceValue - (item.sourceValue * policy.iacValue / policy.fundValue)),
                2);
              break;
            case PostCalculationTargetFieldName.EMPLOYER_REGULAR_CONTRIBUTION_POST_IAC:
              newItem.targetValue = roundDown(
                (item.sourceValue - (item.sourceValue * policy.iacValue / policy.fundValue)),
                2);
              break;
            case PostCalculationTargetFieldName.EMPLOYEE_SINGLE_GROSS_CONTRIBUTION_POST_IAC:
              newItem.targetValue = policy.fundValue
                ? roundDown((item.sourceValue - (policy.iacValue * item.sourceValue / policy.fundValue)) * internalExternalProportions, 2)
                : 0;
              break;
            case PostCalculationTargetFieldName.EMPLOYER_SINGLE_CONTRIBUTION_POST_IAC:
              newItem.targetValue = policy.fundValue
                ? roundDown((item.sourceValue - (policy.iacValue * item.sourceValue / policy.fundValue)) * internalExternalProportions, 2)
                : 0;
              break;
          }
        } else {
          switch (item.targetFieldName) {
            case PostCalculationTargetFieldName.TRANSFER_AFTER_CHARGES:
              newItem.targetValue =
                roundDown((item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100)) * internalExternalProportions, 2);
              break;
            case PostCalculationTargetFieldName.EMPLOYEE_GROSS_AMOUNT_POST_IAC:
              newItem.targetValue = roundDown(
                (item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100)),
                2);
              break;
            case PostCalculationTargetFieldName.EMPLOYER_REGULAR_CONTRIBUTION_POST_IAC:
              newItem.targetValue = roundDown(
                (item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100)),
                2);
              break;
            case PostCalculationTargetFieldName.EMPLOYEE_SINGLE_GROSS_CONTRIBUTION_POST_IAC:
              newItem.targetValue = roundDown((item.sourceValue - (policy.fundValue * totalIACPercentCalc / 100)) * internalExternalProportions, 2);
              break;
            case PostCalculationTargetFieldName.EMPLOYER_SINGLE_CONTRIBUTION_POST_IAC:
              newItem.targetValue = roundDown((item.sourceValue - (policy.fundValue * totalIACPercentCalc / 100)) * internalExternalProportions, 2);
              break;
          }
        }
        postCalculationResult.push(newItem);
      }
    });
    policy.postCalculation = postCalculationResult;
  }
};

const calculatePostCalculationAsset = (policy, isBelowTotalOacLimit, internalExternalProportions, totalIACPercentCalc) => {
  if (Array.isArray(policy.postCalculation) && policy.postCalculation.length > 0) {
    const postCalculationResult = [];
    policy.postCalculation.forEach(item => {
      if (item.sourceValue) {
        const newItem = Object.assign({}, item);
        if (isBelowTotalOacLimit) {
          switch (item.targetFieldName) {
            case PostCalculationTargetFieldName.SINGLE_CONTRIBUTION_POST_IAC:
            case PostCalculationTargetFieldName.TRANSFER_AFTER_CHARGES:
            case PostCalculationTargetFieldName.B_2_B_SINGLE_POST:
            case PostCalculationTargetFieldName.MIG_AC:
              newItem.targetValue = policy.fundValue
                ? mathFloor((item.sourceValue - policy.iacValue * (item.sourceValue / policy.fundValue)) * internalExternalProportions)
                : 0;
              break;
            case PostCalculationTargetFieldName.LUMP_SUM_TOP_UP:
            case PostCalculationTargetFieldName.B_2_B_SINGLE_BEFORE:
            case PostCalculationTargetFieldName.TRANSFER_BEFORE_CHARGES:
              newItem.targetValue = policy.fundValue
                ? mathFloor((item.sourceValue + policy.iacValue * (item.sourceValue / policy.fundValue)) * internalExternalProportions)
                : 0;
              break;
            case PostCalculationTargetFieldName.REGULAR_CONTRIBUTION_POST_IAC:
              newItem.targetValue =
                mathFloor(item.sourceValue - (item.sourceValue * policy.iacValue / policy.fundValue));
              break;
          }
        } else {
          switch (item.targetFieldName) {
            case PostCalculationTargetFieldName.TRANSFER_AFTER_CHARGES:
            case PostCalculationTargetFieldName.B_2_B_SINGLE_POST:
            case PostCalculationTargetFieldName.SINGLE_CONTRIBUTION_POST_IAC:
            case PostCalculationTargetFieldName.MIG_AC:
              newItem.targetValue = mathFloor((item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100)) * internalExternalProportions);
              break;
            case PostCalculationTargetFieldName.TRANSFER_BEFORE_CHARGES:
            case PostCalculationTargetFieldName.B_2_B_SINGLE_BEFORE:
            case PostCalculationTargetFieldName.LUMP_SUM_TOP_UP:
              newItem.targetValue = mathFloor((item.sourceValue + (item.sourceValue * totalIACPercentCalc / 100)) * internalExternalProportions);
              break;
            case PostCalculationTargetFieldName.REGULAR_CONTRIBUTION_POST_IAC:
              newItem.targetValue = mathFloor(item.sourceValue - (item.sourceValue * totalIACPercentCalc / 100));
              break;
          }
        }
        postCalculationResult.push(newItem);
      }
    });
    policy.postCalculation = postCalculationResult;
  }
};

export {
  iacValueLookup,
  lookupValue1,
  multiplyByFrequency,
  roundDown,
  mathRound,
  mathFloor,
  mathCeil,
  calculateIacForMultipleRecommendations,
  calculateNetIsaIacValue,
  calculateFundValueBasedOnIndexation,
  getPostCalculationFields,
  calculatePostCalculation
};
