import Model from './Model';

export class ClientDetails extends Model {
  static createClientDetails (clientName, clientOACstatus, isMaster, partnerId) {
    try {
      const clientDetails = new ClientDetails();
      clientDetails.set('name', clientName ?? '');
      clientDetails.set('oacStatus', clientOACstatus ?? '');
      clientDetails.set('isMaster', isMaster ?? '');
      clientDetails.set('partnerId', partnerId ?? '');
      return clientDetails;
    } catch (e) {
      console.log(e);
    }
  }
}
