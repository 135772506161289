import Model from './Model';

export class SourceOfFund extends Model {
  static createSourceOfFund (data) {
    try {
      const sourceOfFund = new SourceOfFund();

      sourceOfFund.set('key', Array.isArray(data) ? data[0] : '');
      sourceOfFund.set('value', Array.isArray(data) ? data[1] : '');

      return sourceOfFund;
    } catch (e) {
      console.log(e);
    }
  }
}
