import { v4 as uuid } from 'uuid';
import Model from './Model';
import { ClientOACstatus } from '../Enums/ClientOACstatusEnum';
import { iacValueLookup, multiplyByFrequency, roundDown, getPostCalculationFields } from '../Utils/IACUtils';
import { RecommendationType } from '../Enums/RecommendationTypeEnum';
import { filterSourceOfFunds, filterAnnuitySourceOfFunds, filterAssetSourceOfFunds } from '../Utils/FilterUtils';
import { DataGroup } from '../Enums/DataGroupEnum';
import { AnnuityType, AssetType } from '../Enums/TypeEnum';
import { PhasedDrawdown } from '../Enums/PhasedDrawdownEnum';
import { InternalAndExternal } from '../Enums/InternalAndExternalEnum';
import { PolicyOwner } from '../Enums/PolicyOwnerEnum';

export class Policy extends Model {
  static createPolicy (data, configurationData, policiesOwnerType, oacStatus) {
    try {
      const policies = [];
      if (!processWithThisPolicyOwner(data, policiesOwnerType)) {
        return policies;
      }

      const policy = new Policy();
      policy.set('dataGroup', data.fields ? data.fields.data_group : '');

      const recommendationTypes = data.fields ? getRecommendationTypes(data, configurationData) : [];
      const fundValues = data.fields ? getAllFundValues(recommendationTypes, data) : [];
      const internalAndExternal = isInternalAndExternal(policy.dataGroup, data.fields, configurationData.sourceOfFunds, configurationData.sourceOfFundsMulti, configurationData.sourceOfFundsEnum);
      const policySourceOfFunds = getSourceOfFunds(policy.dataGroup, data.fields, configurationData.sourceOfFunds, configurationData.sourceOfFundsMulti, configurationData.sourceOfFundsEnum, internalAndExternal);
      let initialPremiumValue = recommendationTypes[0] === RecommendationType.REGULAR_PREMIUM_GROSS ? fundValues[0] : null;
      let iacValues = iacValueLookup(oacStatus, recommendationTypes[0], policySourceOfFunds, fundValues[0], configurationData.sourceOfFundsEnum);
      const postCalculationFields = data.fields ? getPostCalculationFields(policy.dataGroup, recommendationTypes[0], data.fields) : [];

      policy.set('index', data.index || 0);
      policy.set('key', uuid());
      policy.set('fundOrPolicyNum', data.fields ? getPolicyNumber(data.fields) : '');
      policy.set('inceptionDate', data.fields ? getInceptionDate(data.fields.inception_date) : '');
      policy.set('pensionType', data.fields ? data.fields.pension_type : '');
      policy.set('policyStatus', data.fields ? getPolicyStatus(data.fields) : '');
      policy.set('productName', data.fields ? data.fields.product_name : '');
      policy.set('productProvider', data.fields ? getProductProvider(data.fields) : '');
      policy.set('topUp', data.fields ? data.fields.top_up : '');
      policy.set('type', data.fields ? getType(data, configurationData) : '');
      policy.set('iacPercent', iacValues.iacPercent ? iacValues.iacPercent : '');
      policy.set('iacValue', iacValues.iacValue || iacValues.iacValue === 0 ? iacValues.iacValue : '');
      policy.set('isAdditionalIacCalcNeeded', iacValues.isAdditionalIacCalcNeeded ? iacValues.isAdditionalIacCalcNeeded : '');
      policy.set('internalAndExternal', internalAndExternal ? InternalAndExternal.INTERNAL : null);
      policy.set('sourceOfFunds', policySourceOfFunds);
      policy.set('recommendationType', recommendationTypes[0]);
      policy.set('fundValue', roundDown(fundValues[0], 2));
      policy.set('initialPremiumValue', initialPremiumValue);
      policy.set('oacStatus', oacStatus);
      policy.set('postCalculation', postCalculationFields);
      policy.set('uncrystallisedPcls', data.fields ? data.fields.uncrystallised_pcls || 0 : 0);
      policy.set('pcls', data.fields ? data.fields.pcls || 0 : 0);
      policies.push(policy);

      if (recommendationTypes.length > 1) {
        for (let i = 1; i < recommendationTypes.length; ++i) {
          initialPremiumValue = recommendationTypes[i] === RecommendationType.REGULAR_PREMIUM_GROSS ? fundValues[i] : null;
          const newPolicy = Object.assign({}, policy);
          newPolicy.recommendationType = recommendationTypes[i];
          newPolicy.fundValue = fundValues[i];
          newPolicy.initialPremiumValue = initialPremiumValue;
          iacValues = iacValueLookup(oacStatus, recommendationTypes[i], policySourceOfFunds, fundValues[i], configurationData.sourceOfFundsEnum);
          newPolicy.iacPercent = iacValues.iacPercent ? iacValues.iacPercent : '';
          newPolicy.iacValue = iacValues.iacValue || iacValues.iacValue === 0 ? iacValues.iacValue : '';
          newPolicy.isAdditionalIacCalcNeeded = iacValues.isAdditionalIacCalcNeeded ? iacValues.isAdditionalIacCalcNeeded : '';
          newPolicy.key = uuid();
          newPolicy.postCalculation = data.fields ? getPostCalculationFields(policy.dataGroup, recommendationTypes[i], data.fields) : [];
          policies.push(newPolicy);
        }
      }

      for (let i = 0; i < policies.length; i++) {
        if (policies[i].internalAndExternal === InternalAndExternal.INTERNAL && policies[i].recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
          policies[i].internalAndExternal = null;
        }
        if (policies[i].internalAndExternal === InternalAndExternal.INTERNAL && policies[i].recommendationType === RecommendationType.FULL_UFPLS && oacStatus === ClientOACstatus.NON_OAC) {
          policies[i].internalAndExternal = null;
          policies[i].sourceOfFunds = configurationData.sourceOfFundsEnum.general[3];
        }
        if (policies[i].internalAndExternal === InternalAndExternal.INTERNAL) {
          const newPolicy = Object.assign({}, policies[i]);
          newPolicy.key = uuid();
          newPolicy.fundValue = 0;
          newPolicy.initialPremiumValue = 0;
          newPolicy.sourceOfFunds = configurationData.sourceOfFundsEnum.general[1];
          iacValues = iacValueLookup(oacStatus, newPolicy.recommendationType, newPolicy.sourceOfFunds, 0, configurationData.sourceOfFundsEnum);
          newPolicy.iacPercent = iacValues.iacPercent ? iacValues.iacPercent : '';
          newPolicy.iacValue = iacValues.iacValue || iacValues.iacValue === 0 ? iacValues.iacValue : '';
          newPolicy.isAdditionalIacCalcNeeded = iacValues.isAdditionalIacCalcNeeded ? iacValues.isAdditionalIacCalcNeeded : '';
          newPolicy.internalAndExternal = InternalAndExternal.EXTERNAL;
          newPolicy.internalPolicyKey = policies[i].key;
          newPolicy.internalPolicyValue = policies[i].fundValue;
          policies.splice(i + 1, 0, newPolicy);
        }
      }

      return policies;
    } catch (e) {
      console.error(e);
    }
  }
}

const getPolicyOwner = (data) => {
  return data.fields && data.fields.owner ? data.fields.owner : PolicyOwner.NOT_SET;
};

const processWithThisPolicyOwner = (data, policiesOwnerType) => {
  const thisPolicyOwner = getPolicyOwner(data);
  return policiesOwnerType === PolicyOwner.JOINT
    ? thisPolicyOwner === policiesOwnerType
    : thisPolicyOwner === policiesOwnerType || thisPolicyOwner === PolicyOwner.NOT_SET;
};

const isInternalAndExternal = (dataGroup, fields, sourceOfFundsConfig, sourceOfFundsMultiConfig, sourceOfFundsEnum) => {
  if (dataGroup === DataGroup.FUND_ZA) {
    return fields && isInternalAndExternalFundZa(sourceOfFundsConfig, fields.source_of_funds, sourceOfFundsEnum) ? InternalAndExternal.INTERNAL : null;
  } else if (dataGroup === DataGroup.ANNUITY) {
    return fields && isInternalAndExternalAnnuity(fields.source_of_funds_multi, sourceOfFundsEnum) ? InternalAndExternal.INTERNAL : null;
  } else if (dataGroup === DataGroup.ASSET) {
    return fields && isInternalAndExternalAsset(sourceOfFundsMultiConfig, fields.source_of_funds_multi, sourceOfFundsEnum) ? InternalAndExternal.INTERNAL : null;
  } else return null;
};

const isInternalAndExternalFundZa = (sourceOfFundsConfig, itemSourceOfFunds, sourceOfFundsEnum) => {
  if (!itemSourceOfFunds) {
    return false;
  }
  const itemSourceOfFundsValue = itemSourceOfFunds.flatMap(item => sourceOfFundsConfig.filter(data => data.key === item)).map(filtered => filtered.value);
  const isExternal = itemSourceOfFundsValue.some(sourceOfFunds => sourceOfFundsEnum.fundZaExternal.includes(sourceOfFunds));
  const isInternal = itemSourceOfFundsValue.some(sourceOfFunds => sourceOfFundsEnum.fundZaInternal.includes(sourceOfFunds));
  return (isExternal && isInternal) || (!isExternal && !isInternal);
};

const isInternalAndExternalAnnuity = (itemSourceOfFunds, sourceOfFundsEnum) => {
  if (!itemSourceOfFunds) {
    return false;
  }
  const isExternal = itemSourceOfFunds.some(sourceOfFunds => sourceOfFundsEnum.annuityExternal.includes(sourceOfFunds));
  const isInternal = itemSourceOfFunds.some(sourceOfFunds => sourceOfFundsEnum.annuityInternal.includes(sourceOfFunds));
  return (isExternal && isInternal) || (!isExternal && !isInternal);
};

const isInternalAndExternalAsset = (sourceOfFundsConfig, itemSourceOfFunds, sourceOfFundsEnum) => {
  if (!itemSourceOfFunds) {
    return false;
  }
  const itemSourceOfFundsValue = itemSourceOfFunds.flatMap(item => sourceOfFundsConfig.filter(data => data.key === item)).map(filtered => filtered.value);
  const isExternal = itemSourceOfFundsValue.some(sourceOfFunds => sourceOfFundsEnum.assetExternal.includes(sourceOfFunds));
  const isInternal = itemSourceOfFundsValue.some(sourceOfFunds => sourceOfFundsEnum.assetInternal.includes(sourceOfFunds));
  return (isExternal && isInternal) || (!isExternal && !isInternal);
};

const getFundZaSourceOfFunds = (AllSourceOfFunds, itemSourceOfFunds, sourceOfFundsEnum) => {
  return itemSourceOfFunds
    ? filterSourceOfFunds(AllSourceOfFunds.filter(data => data.key === itemSourceOfFunds[0]).map(filtered => filtered.value), sourceOfFundsEnum)
    : '';
};

const getAnnuitySourceOfFunds = (itemSourceOfFunds, sourceOfFundsEnum) => {
  return itemSourceOfFunds ? filterAnnuitySourceOfFunds(itemSourceOfFunds, sourceOfFundsEnum) : '';
};

const getAssetSourceOfFunds = (AllSourceOfFunds, itemSourceOfFunds, sourceOfFundsEnum) => {
  return itemSourceOfFunds
    ? filterAssetSourceOfFunds(AllSourceOfFunds.filter(data => data.key === itemSourceOfFunds[0]).map(filtered => filtered.value), sourceOfFundsEnum)
    : '';
};

const getSourceOfFunds = (dataGroup, fields, sourceOfFundsConfig, sourceOfFundsMultiConfig, sourceOfFundsEnum, isInternalAndExternal) => {
  if (isInternalAndExternal) {
    return sourceOfFundsEnum.general[0];
  } else if (dataGroup === DataGroup.FUND_ZA) {
    return fields ? getFundZaSourceOfFunds(sourceOfFundsConfig, fields.source_of_funds, sourceOfFundsEnum) : '';
  } else if (dataGroup === DataGroup.ANNUITY) {
    return fields ? getAnnuitySourceOfFunds(fields.source_of_funds_multi, sourceOfFundsEnum) : '';
  } else if (dataGroup === DataGroup.ASSET) {
    return fields ? getAssetSourceOfFunds(sourceOfFundsMultiConfig, fields.source_of_funds_multi, sourceOfFundsEnum) : '';
  } else return '';
};

const getGroupType = (AllGroupTypes, itemType) => {
  return itemType
    ? AllGroupTypes.filter(data => data.key === itemType).map(filtered => filtered.value)[0]
    : '';
};

const getAllFundValues = (recommendationTypes, data) => {
  const fundValues = [];

  switch (data.fields.data_group) {
    case DataGroup.FUND_ZA :
      recommendationTypes.forEach(recommendationType => {
        fundValues.push(getFundValueForFundZa(recommendationType, data));
      });
      break;
    case DataGroup.ANNUITY :
      recommendationTypes.forEach(recommendationType => {
        fundValues.push(getFundValueForAnnuity(recommendationType, data));
      });
      break;
    case DataGroup.ASSET :
      recommendationTypes.forEach(recommendationType => {
        fundValues.push(getFundValueForAsset(recommendationType, data));
      });
      break;
  }

  return fundValues;
};

const getFundValueForFundZa = (recommendationType, data) => {
  if (recommendationType === RecommendationType.PENSION_SWITCH ||
    recommendationType === RecommendationType.PENSION_SWITCH_IMMEDIATE_VESTING) {
    if (data.fields.trnsfbc) {
      return roundDown(Number(data.fields.trnsfbc), 2);
    } else {
      return 0;
    }
  } else if (recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
    let fundValue = 0;
    if (data.fields.lump_sum_top_up) {
      fundValue = Number(data.fields.lump_sum_top_up);
    }
    if (data.fields.employer_regular_contribution) {
      fundValue += Number(data.fields.employer_regular_contribution);
    }
    return roundDown(fundValue, 2);
  } else if (recommendationType === RecommendationType.SINGLE_PREMIUM_PENSION) {
    let fundValue = 0;
    if (data.fields.employee_single_gross_contribution) {
      fundValue = Number(data.fields.employee_single_gross_contribution);
    }
    if (data.fields.employer_single_contribution) {
      fundValue += Number(data.fields.employer_single_contribution);
    }
    return roundDown(fundValue, 2);
  }

  return 0;
};

const getFundValueForAnnuity = (recommendationType, data) => {
  if (recommendationType === RecommendationType.FULL_DRAWDOWN ||
    recommendationType === RecommendationType.PART_DRAWDOWN) {
    if (data.fields.uncrystallised_tfr_amount) {
      return roundDown(Number(data.fields.uncrystallised_tfr_amount), 2);
    } else {
      return 0;
    }
  } else if (recommendationType === RecommendationType.REGULAR_UFPLS) {
    if (data.fields.policy_transfer_value) {
      return roundDown(Number(data.fields.policy_transfer_value), 2);
    } else {
      return 0;
    }
  } else if (recommendationType === RecommendationType.FULL_UFPLS ||
             recommendationType === RecommendationType.PART_UFPLS) {
    if (data.fields.ufplslump) {
      return roundDown(Number(data.fields.ufplslump), 2);
    } else {
      return 0;
    }
  } else if (recommendationType === RecommendationType.ANNUITY ||
    recommendationType === RecommendationType.SECONDARY_PENSION_BENEFIT) {
    if (data.fields.gross_pension_fund) {
      return roundDown(Number(data.fields.gross_pension_fund), 2);
    } else {
      return 0;
    }
  } else if (recommendationType === RecommendationType.REGULAR_UFPLS_TERM) {
    const reg = multiplyByFrequency(data.fields.reg_ufpls_bc, data.fields.reg_ufpls) * Number(data.fields.autophase_term);
    const ap = multiplyByFrequency(data.fields.ap_initial, data.fields.ap_initial_frq) * Number(data.fields.autophase_term);
    return roundDown(reg + ap, 2);
  } else if (recommendationType === RecommendationType.DEPENDENT_NOPMINEE_ANNUITY) {
    return Math.round((Number(data.fields.uncrystallised_tfr_amount) + Number(data.fields.gross_pension_fund)) * 100) / 100;
  }

  return 0;
};

const getFundValueForAsset = (recommendationType, data) => {
  const lumpSumTopUp = data.fields.lump_sum_top_up ? Number(data.fields.lump_sum_top_up) : 0;
  const transferBeforeChanges = data.fields.transfer_before_charges ? Number(data.fields.transfer_before_charges) : 0;
  const transferAfterChanges = data.fields.transfer_after_charges ? Number(data.fields.transfer_after_charges) : 0;
  const migBc = data.fields.mig_bc ? Number(data.fields.mig_bc) : 0;
  const b2bSingleBefore = data.fields.b2b_single_before ? Number(data.fields.b2b_single_before) : 0;
  const b2bSinglePost = data.fields.b2b_single_post ? Number(data.fields.b2b_single_post) : 0;
  const regularContributionBeforeIac = data.fields.regular_contribution_before_iac ? Number(data.fields.regular_contribution_before_iac) : 0;
  const singleContributionPostIac = data.fields.single_contribution_post_iac ? Number(data.fields.single_contribution_post_iac) : 0;

  if (recommendationType === RecommendationType.ISA_INCL_IAC) {
    return roundDown(lumpSumTopUp + transferBeforeChanges + b2bSingleBefore, 2);
  } else if (recommendationType === RecommendationType.ISA_NOT_INCL_IAC) {
    return roundDown(singleContributionPostIac + transferAfterChanges + b2bSinglePost, 2);
  } else if (recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
    return roundDown(regularContributionBeforeIac, 2);
  } else if (recommendationType === RecommendationType.BOND) {
    return roundDown(lumpSumTopUp, 2);
  } else if (recommendationType === RecommendationType.OEIC) {
    return roundDown(lumpSumTopUp + migBc, 2);
  }

  return roundDown(lumpSumTopUp + transferBeforeChanges + migBc + b2bSingleBefore + regularContributionBeforeIac, 2);
};

const getInceptionDate = (inceptionDate) => {
  if (inceptionDate && inceptionDate._val) {
    return inceptionDate._val;
  }
  return '';
};

const getPolicyStatus = (fields) => {
  switch (fields.data_group) {
    case DataGroup.FUND_ZA :
    case DataGroup.ANNUITY :
      return fields.policy_status;
    case DataGroup.ASSET :
      return fields.status;
  }
};

const getPolicyNumber = (fields) => {
  switch (fields.data_group) {
    case DataGroup.FUND_ZA :
      return fields.fund_or_policy_num;
    case DataGroup.ANNUITY :
    case DataGroup.ASSET :
      return fields.policy_number;
  }
};

const getProductProvider = (fields) => {
  switch (fields.data_group) {
    case DataGroup.FUND_ZA :
    case DataGroup.ANNUITY :
      return fields.product_provider;
    case DataGroup.ASSET :
      return fields.provider;
  }
};

const getRecommendationTypes = (data, configurationData) => {
  const foundRecommendationTypes = [];
  const fields = data.fields;

  switch (fields.data_group) {
    case DataGroup.FUND_ZA :
      return getRecommendationTypesForFundZa(fields, foundRecommendationTypes);
    case DataGroup.ANNUITY :
      return getRecommendationTypesForAnnuity(fields, foundRecommendationTypes, configurationData);
    case DataGroup.ASSET :
      return getRecommendationTypesForAsset(fields, foundRecommendationTypes, configurationData);
    default :
      return [RecommendationType.CANNOT_DETERMINE];
  }
};

const getRecommendationTypesForFundZa = (fields, recommendationTypesList) => {
  if (fields.is_the_source_of_any_of_the_funds_from_another_pension_ && fields.immediate_vest === 'No') {
    recommendationTypesList.push(RecommendationType.PENSION_SWITCH);
  }

  if (fields.is_the_source_of_any_of_the_funds_from_another_pension_ === 0 && fields.immediate_vest === 'Yes') {
    recommendationTypesList.push(RecommendationType.PENSION_SWITCH_IMMEDIATE_VESTING);
  }

  if (fields.employee_net_amount ||
      fields.lump_sum_top_up ||
      fields.employee_gross_amount_post_iac ||
      fields.employer_regular_contribution ||
      fields.employer_regular_contribution_post_iac) {
    recommendationTypesList.push(RecommendationType.REGULAR_PREMIUM_GROSS);
  }

  if (fields.employee_single_net_contribution ||
      fields.employee_single_gross_contribution ||
      fields.employee_single_gross_contribution_post_iac ||
      fields.employer_single_contribution ||
      fields.employer_single_contribution_post_iac) {
    recommendationTypesList.push(RecommendationType.SINGLE_PREMIUM_PENSION);
  }

  if (recommendationTypesList.length >= 1) {
    return recommendationTypesList;
  } else {
    return [RecommendationType.CANNOT_DETERMINE];
  }
};

const getRecommendationTypesForAnnuity = (fields, recommendationTypesList, configurationData) => {
  const annuityType = getGroupType(configurationData.annuityTypes, fields.type);
  if (annuityType === AnnuityType.INCOME_DRAWDOWN &&
    fields.uncrystallised_tfr_amount > 0 &&
    (fields.phased_drawdown === 'N/A' || !fields.phased_drawdown) &&
    fields.dependants_drawdown !== '1') {
    recommendationTypesList.push(RecommendationType.FULL_DRAWDOWN);
  }

  if ((annuityType === AnnuityType.INCOME_DRAWDOWN || annuityType === AnnuityType.PHASED_RETIREMENT || annuityType === AnnuityType.AD_HOC_DRAWDOWN) &&
    (fields.phased_drawdown === PhasedDrawdown.PARTIAL) &&
    fields.dependants_drawdown !== '1') {
    recommendationTypesList.push(RecommendationType.PART_DRAWDOWN);
  }

  if (annuityType === AnnuityType.UNCRYSTALISED_FUNDS_PENSION_LUMP_SUM && fields.ufplslump === fields.ufplscurrent) {
    recommendationTypesList.push(RecommendationType.FULL_UFPLS);
  }

  if (annuityType === AnnuityType.UNCRYSTALISED_FUNDS_PENSION_LUMP_SUM && fields.ufplscurrent > fields.ufplslump) {
    recommendationTypesList.push(RecommendationType.PART_UFPLS);
  }

  if ((annuityType === AnnuityType.COMPULSORY_PURCHASE_LIFE_ANNUITY ||
      annuityType === AnnuityType.IMMEDIATE_PURCHASE_LIFE_ANNUITY ||
      annuityType === AnnuityType.IMMEDIATE_VESTING_PERSONAL_PENSION ||
      annuityType === AnnuityType.TEMPORARY_ANNUITY ||
      annuityType === AnnuityType.ENHANCED_ANNUITY ||
      annuityType === AnnuityType.DEF_ANNUITY ||
      annuityType === AnnuityType.UNIT_LINKED_ANNUITY) &&
    fields.gross_pension_fund > 0 &&
    fields.drawdown_to_annuity !== 1 &&
    fields.dependants_drawdown !== '1') {
    recommendationTypesList.push(RecommendationType.ANNUITY);
  }

  if ((annuityType === AnnuityType.INCOME_DRAWDOWN || annuityType === AnnuityType.PHASED_RETIREMENT) &&
    fields.policy_transfer_value > 0 &&
    (fields.autophase_term === '00' || !fields.autophase_term)) {
    recommendationTypesList.push(RecommendationType.REGULAR_UFPLS);
  }

  if ((annuityType === AnnuityType.INCOME_DRAWDOWN || annuityType === AnnuityType.PHASED_RETIREMENT) &&
    (fields.reg_ufpls_bc > 0 || fields.ap_initial > 0) &&
    (fields.autophase_term !== '00' && fields.autophase_term)) {
    recommendationTypesList.push(RecommendationType.REGULAR_UFPLS_TERM);
  }

  if ((annuityType === AnnuityType.COMPULSORY_PURCHASE_LIFE_ANNUITY ||
    annuityType === AnnuityType.IMMEDIATE_PURCHASE_LIFE_ANNUITY ||
    annuityType === AnnuityType.ENHANCED_ANNUITY ||
    annuityType === AnnuityType.IMMEDIATE_VESTING_PERSONAL_PENSION ||
    annuityType === AnnuityType.TEMPORARY_ANNUITY ||
    annuityType === AnnuityType.DEF_ANNUITY ||
    annuityType === AnnuityType.UNIT_LINKED_ANNUITY) &&
    fields.gross_pension_fund > 0 &&
    fields.drawdown_to_annuity === 1) {
    recommendationTypesList.push(RecommendationType.SECONDARY_PENSION_BENEFIT);
  }

  if ((annuityType === AnnuityType.INCOME_DRAWDOWN ||
    annuityType === AnnuityType.PHASED_RETIREMENT ||
    annuityType === AnnuityType.AD_HOC_DRAWDOWN ||
    annuityType === AnnuityType.COMPULSORY_PURCHASE_LIFE_ANNUITY ||
    annuityType === AnnuityType.IMMEDIATE_PURCHASE_LIFE_ANNUITY ||
    annuityType === AnnuityType.IMMEDIATE_VESTING_PERSONAL_PENSION ||
    annuityType === AnnuityType.TEMPORARY_ANNUITY ||
    annuityType === AnnuityType.ENHANCED_ANNUITY ||
    annuityType === AnnuityType.DEF_ANNUITY ||
    annuityType === AnnuityType.UNIT_LINKED_ANNUITY) &&
    fields.dependants_drawdown === '1') {
    recommendationTypesList.push(RecommendationType.DEPENDENT_NOPMINEE_ANNUITY);
  }

  if (recommendationTypesList.length >= 1) {
    return recommendationTypesList;
  } else {
    return [RecommendationType.CANNOT_DETERMINE];
  }
};

const getRecommendationTypesForAsset = (fields, recommendationTypesList, configurationData) => {
  const assetType = getGroupType(configurationData.assetTypes, fields.type);
  if (assetType === AssetType.STOCKS_SHARES &&
      (fields.lump_sum_top_up > 0 || fields.transfer_before_charges > 0 || fields.b2b_single_before > 0)) {
    recommendationTypesList.push(RecommendationType.ISA_INCL_IAC);
  }

  if (assetType === AssetType.STOCKS_SHARES &&
      (fields.single_contribution_post_iac > 0 || fields.transfer_after_charges > 0 || fields.b2b_single_post)) {
    recommendationTypesList.push(RecommendationType.ISA_NOT_INCL_IAC);
  }

  if ((assetType === AssetType.STOCKS_SHARES || assetType === AssetType.OEIC || assetType === AssetType.UNIT_TRUST) &&
      fields.regular_contribution_before_iac > 0) {
    recommendationTypesList.push(RecommendationType.REGULAR_PREMIUM_GROSS);
  }

  if (assetType === AssetType.INVESTMENT_BOND) {
    recommendationTypesList.push(RecommendationType.BOND);
  }

  if (assetType === AssetType.OEIC && (fields.lump_sum_top_up > 0 || fields.mig_bc > 0)) {
    recommendationTypesList.push(RecommendationType.OEIC);
  }

  if (recommendationTypesList.length >= 1) {
    return recommendationTypesList;
  } else {
    return [RecommendationType.CANNOT_DETERMINE];
  }
};

const getType = (data, configurationData) => {
  const fields = data.fields;

  switch (fields.data_group) {
    case DataGroup.FUND_ZA :
      return fields.type.toString();
    case DataGroup.ANNUITY :
      return getGroupType(configurationData.annuityTypes, fields.type);
    case DataGroup.ASSET :
      return getGroupType(configurationData.assetTypes, fields.type);
  }
};
