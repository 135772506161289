export const AnnuityType = {
  PHASED_RETIREMENT: 'Phased Retirement',
  INCOME_DRAWDOWN: 'Income Drawdown',
  AD_HOC_DRAWDOWN: 'Ad-Hoc Drawdown',
  COMPULSORY_PURCHASE_LIFE_ANNUITY: 'Compulsory Purchase Life Annuity',
  IMMEDIATE_PURCHASE_LIFE_ANNUITY: 'Immediate Purchase Life Annuity',
  IMMEDIATE_VESTING_PERSONAL_PENSION: 'Immediate Vesting Personal Pension',
  TEMPORARY_ANNUITY: 'Temporary Annuity',
  ENHANCED_ANNUITY: 'Enhanced Annuity',
  DEF_ANNUITY: 'Def Annuity',
  UNIT_LINKED_ANNUITY: 'Unit Linked Annuity',
  OTHER: 'Other',
  UNCRYSTALISED_FUNDS_PENSION_LUMP_SUM: 'Uncrystalised Funds Pension Lump Sum'
};

export const AssetType = {
  STOCKS_SHARES: 'Stocks & Shares ISA',
  OEIC: 'OEIC',
  UNIT_TRUST: 'Unit Trust',
  INVESTMENT_BOND: 'Investment Bond'
};
