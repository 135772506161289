import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Image } from 'react-bootstrap';
import Modal from 'react-modal';

function Alert ({ message, options: { header, buttonText, callback } }) {
  const [showModal, setShowModal] = useState(true);

  const onClickPrimaryButton = () => {
    if (typeof callback === 'function') {
      callback();
    }
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={showModal} style={modalStyles}>
        <Card className="card-header">
          {header}
        </Card>
        <Card>
          <div className="alert-image-container">
            <Image className="alert-image" src="icon-warning.png" />
          </div>
          <div className="alert-message-container">
            {message}
          </div>
          <div className="alert-button-container">
            <Button className="button" onClick={onClickPrimaryButton}>
              {buttonText || 'Ok'}
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
}

const options = {
  timeout: 0
};

const modalStyles = {
  overlay: {
    backgroundColor: 'transparent'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0px',
    border: 0,
    padding: 0,
    width: '400px',
    boxShadow: 'rgb(204, 204, 204) 0px 0px 5px 0px'
  }
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  options: PropTypes.shape({
    header: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    callback: PropTypes.func
  })
};

export { Alert, options };
