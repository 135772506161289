import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function ScreenshotIndexation ({ updateIndexation, indexationValue }) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (updateIndexation) {
      setValue(indexationValue);
    }
  }, [updateIndexation, indexationValue]);

  return (
    <>
        <span className="indexation-span">{value}</span>
    </>
  );
}

ScreenshotIndexation.propTypes = {
  updateIndexation: PropTypes.string,
  indexationValue: PropTypes.string
};

export default ScreenshotIndexation;
