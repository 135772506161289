const checkIfCurrencyField = (field) => {
  return field === 'initial_amount';
};

const addCurrencyField = (field) => {
  const currencyField = {
    _val:
            {
              code: 'GBP',
              value: {
                _val: field,
                _type: 'BigDecimal'
              }
            },
    _type: 'Currency'
  };
  return currencyField;
};

export {
  checkIfCurrencyField,
  addCurrencyField
};
