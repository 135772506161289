import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { RecommendationType } from '../Enums/RecommendationTypeEnum';
import { sendCalculationsResult } from '../Services/ClientService';
import { InternalAndExternal } from '../Enums/InternalAndExternalEnum';
import { formatName, combineAllPolicies } from '../Utils/ClientUtils';
import FundValue from './FundValue';
import { takeScreenshot } from '../Utils/ScreenshotUtils';
import { Action, Message } from '../Enums/MessageEnum';
import { IndexationData } from '../Transforms/IndexationData';
import ScreenshotIndexation from './ScreenshotIndexation';

// READ this to understand some namings here
// client which id was entered by adviser is called 'client' here
// client which id was NOT entered by adviser is called 'partner' here
// client can be master and not master
// if client is master then partner is not master; if client is not master then partner is master
// master is called 'clientMaster' and not master is called 'clientNotMaster' here
// so if clientIsMaster flag is true then adviser entered id of client which is master otherwise entered id of client which is not master
function Products ({ policiesDetails, clientId, partnerId, clientIsMaster, clientName, partnerName, setInternalExternalPolicyValues, setPolicyIndexation, manageNotifications }) {
  const HIDE_COLUMNS = JSON.stringify(process.env.REACT_APP_HIDE_COLUMNS) === JSON.stringify('true');
  const [isIndexed, setIndexed] = useState(false);
  const [indexationForScreenshot, setIndexationForScreenshot] = useState(IndexationData.createIacValues('', ''));

  useEffect(() => checkIfIndexed(), [policiesDetails]);

  const onSubmitIACButtonClick = async (e) => {
    e.preventDefault();
    const screenshot = await takeScreenshot();
    sendCalculationsResult(clientId, partnerId, policiesDetails,
      policiesDetails.summary.totalIAC, policiesDetails.summary.totalIACPercent, screenshot).then(() => {
      console.log('This is us patching the calculation values successfully');
    });
  };

  const onIndexationChange = (item, value) => {
    setPolicyIndexation(item.key, value ? parseFloat(value) / 100 : '');
  };

  const clientHasPartner = () => {
    return partnerName.length > 1;
  };

  const renderRow = (item) => {
    return (
      <tr key={item.key}>
        <td hidden={HIDE_COLUMNS}>{item.productName}</td>
        <td>
          { item.recommendationType === RecommendationType.CANNOT_DETERMINE
            ? <span style={{ color: 'red' }}> <b>{item.recommendationType}</b> </span>
            : item.recommendationType }
        </td>
        <td hidden={HIDE_COLUMNS}>{item.index}</td>
        <td hidden={HIDE_COLUMNS}>{item.policyStatus}</td>
        <td hidden={HIDE_COLUMNS}>{item.topUp}</td>
        <td hidden={HIDE_COLUMNS}>{item.fundOrPolicyNum}</td>
        <td>
          <FundValue item={item} setInternalExternalPolicyValues={setInternalExternalPolicyValues} />
        </td>
        <td>{renderIndexation(item)}</td>
        <td hidden={HIDE_COLUMNS}>{item.inceptionDate}</td>
        <td hidden={HIDE_COLUMNS}>{item.pensionType}</td>
        <td hidden={HIDE_COLUMNS}>{item.productProvider}</td>
        <td hidden={HIDE_COLUMNS}>{item.type}</td>
        <td>{item.sourceOfFunds}</td>
        <td>{item.iacPercent}</td>
        <td>{item.iacValue}</td>
        <td hidden={HIDE_COLUMNS}>{renderPostCalculation(item.postCalculation)}</td>
      </tr>
    );
  };

  const renderTotal = () => {
    if (policiesDetails.summary.totalFundValue && isIndexed) {
      return (
        <tr>
          <td>Total</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td>{policiesDetails.summary.totalFundValue}</td>
          <td>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td hidden={HIDE_COLUMNS}>**</td>
          <td>**</td>
          <td>{ HIDE_COLUMNS ? '**' : policiesDetails.summary.totalIACPercent + '%' }</td>
          <td>{policiesDetails.summary.totalIAC}</td>
        </tr>
      );
    } else {
      return null;
    }
  };

  const renderSubmitIAC = () => {
    if (policiesDetails.summary.totalFundValue && isIndexed) {
      return (
        <Button type="submit" className="button-primary" onClick={onSubmitIACButtonClick}>
            Submit IAC
        </Button>
      );
    } else {
      return null;
    }
  };

  const renderIndexation = (item) => {
    if (item.recommendationType && item.recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
      return (
           <div className="display-inline">
              <Form.Control
                  as="select"
                  className="select select-indexation"
                  onChange={e => { setIndexationForScreenshot(IndexationData.createIacValues(item.key, e.nativeEvent.target[e.target.selectedIndex].text)); onIndexationChange(item, e.target.value); }}
                  data-html2canvas-ignore="true"
              >
                  {getIndexationOptions()}
              </Form.Control>
              <ScreenshotIndexation updateIndexation={indexationForScreenshot.get('key') === item.key} indexationValue={indexationForScreenshot.get('value')} />
           </div>
      );
    }
    return null;
  };

  const renderPostCalculation = (postCalculation) => {
    return postCalculation.map(item => `${item.targetFieldName}: ${item.targetValue} `);
  };

  const getIndexationOptions = () => {
    const MIN_INDEXATION_VALUE = 0;
    const MAX_INDEXATION_VALUE = 10;
    const INDEXATION_STEP = 0.5;
    const options = [];
    options.push(<option key={1} value={''}></option>);
    let key = 2;
    for (let i = MIN_INDEXATION_VALUE; i <= MAX_INDEXATION_VALUE; i++) {
      options.push(<option key={key} value={i}>{i}%</option>);
      key++;
      if (i !== MAX_INDEXATION_VALUE) {
        options.push(<option key={key} value={i + INDEXATION_STEP}>{i + INDEXATION_STEP}%</option>);
        key++;
      }
    }
    return options;
  };

  const checkIfIndexed = () => {
    let breakLoop = false;
    if (policiesDetails) {
      const allPolicies = combineAllPolicies(policiesDetails.clientPolicies, policiesDetails.partnerPolicies, policiesDetails.jointPolicies);
      allPolicies.forEach(policy => {
        if (policy.recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS && !policy.isIndexed && !breakLoop) {
          setIndexed(false);
          manageNotifications(Action.ADD, Message.REGULAR_PREMIUM_GROSS_INDEXATION_REQUIRED);
          breakLoop = true;
        }
      });
      if (!breakLoop) {
        setIndexed(true);
        manageNotifications(Action.DELETE, Message.REGULAR_PREMIUM_GROSS_INDEXATION_REQUIRED);
      }
    }
  };

  const clientMasterHasPolicies = () => {
    return clientIsMaster ? policiesDetails.clientPolicies.length > 0 : policiesDetails.partnerPolicies.length > 0;
  };

  const clientNotMasterHasPolicies = () => {
    return partnerIsMaster() ? policiesDetails.clientPolicies.length > 0 : policiesDetails.partnerPolicies.length > 0;
  };

  const partnerIsMaster = () => {
    return !clientIsMaster;
  };

  const hasJointPolicies = () => {
    return policiesDetails.jointPolicies.length > 0;
  };

  const countAllPolicies = () => {
    return policiesDetails.clientPolicies.length + policiesDetails.partnerPolicies.length + policiesDetails.jointPolicies.length;
  };

  const renderTableHeaders = (showPostCalculation) => {
    return (
        <thead>
        <tr>
            <th hidden={HIDE_COLUMNS}>Product Name</th>
            <th>Recommendation Type</th>
            <th hidden={HIDE_COLUMNS}>Index</th>
            <th hidden={HIDE_COLUMNS}>Policy Status</th>
            <th hidden={HIDE_COLUMNS}>Top Up</th>
            <th hidden={HIDE_COLUMNS}>Fund Or Policy Num</th>
            <th>Value</th>
            <th>Indexation</th>
            <th hidden={HIDE_COLUMNS}>Inception Date</th>
            <th hidden={HIDE_COLUMNS}>Pension Type</th>
            <th hidden={HIDE_COLUMNS}>Product Provider</th>
            <th hidden={HIDE_COLUMNS}>Type</th>
            <th>Source of Funds</th>
            <th>IAC%</th>
            <th>IAC (£)</th>
            {showPostCalculation && <th hidden={HIDE_COLUMNS}>Post Calculation</th>}
        </tr>
        </thead>
    );
  };

  const renderTableWithClientMasterPolicies = () => {
    if (partnerIsMaster() && !clientMasterHasPoliciesOrClientMasterIdWasEnteredByAdviser()) {
      return null;
    } else if (clientIsMaster && !clientMasterHasPolicies() && clientNotMasterHasPolicies()) {
      return (
      <Card>
          <Card.Title>{<b>{formatName(clientName)}</b>}</Card.Title>
          <Card.Body><div>No recommendations</div></Card.Body>
      </Card>);
    } else {
      return (
      <Card>
        <Card.Title>{<b>{clientIsMaster ? formatName(clientName) : formatName(partnerName)}</b>}</Card.Title>
        <Card.Body>
          <Table striped borderless responsive size="sm">
            {renderTableHeaders(true)}
            <tbody>
              {clientIsMaster ? policiesDetails.clientPolicies.map(item => renderRow(item)) : policiesDetails.partnerPolicies.map(item => renderRow(item))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>);
    }
  };

  const renderTableWithClientNotMasterPolicies = () => {
    if (clientHasPartner() && clientNotMasterHasPoliciesOrClientNotMasterIdWasEnteredByAdviser()) {
      return (
        <Card>
          <Card.Title>{<b>{clientIsMaster ? formatName(partnerName) : formatName(clientName)}</b>}</Card.Title>
          <Card.Body>
            {clientNotMasterHasPolicies()
              ? <Table striped borderless responsive size="sm">
                {renderTableHeaders(true)}
                <tbody>
                  {clientIsMaster ? policiesDetails.partnerPolicies.map(item => renderRow(item)) : policiesDetails.clientPolicies.map(item => renderRow(item))}
                </tbody>
              </Table>
              : <div>No recommendations</div>}
          </Card.Body>
        </Card>
      );
    }

    return null;
  };

  const clientNotMasterHasPoliciesOrClientNotMasterIdWasEnteredByAdviser = () => {
    return clientNotMasterHasPolicies() || partnerIsMaster();
  };

  const clientMasterHasPoliciesOrClientMasterIdWasEnteredByAdviser = () => {
    return clientMasterHasPolicies() || clientIsMaster;
  };

  const renderTableWithJointPolicies = () => {
    if (clientHasPartner() && hasJointPolicies()) {
      return (
        <Card>
          <Card.Title><b>Joint</b></Card.Title>
          <Card.Body>
            <Table striped borderless responsive size="sm">
              {renderTableHeaders(true)}
              <tbody>
                {policiesDetails.jointPolicies.map(item => renderRow(item))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      );
    }

    return null;
  };

  const renderTableWithTotal = () => {
    if (countAllPolicies() > 0) {
      return (
        <Card>
          <Card.Title><b>Total</b></Card.Title>
          <Card.Body>
            <Table striped borderless responsive size="sm">
              {renderTableHeaders(false)}
              <tbody>
                {renderTotal()}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      );
    }

    return null;
  };

  if (policiesDetails) {
    // TODO: the checking for other types of policies should be added with the one of the next tickets
    return (
      <>
        <Card className="card-header">
          Products
        </Card>
        {renderTableWithClientMasterPolicies()}
        {renderTableWithClientNotMasterPolicies()}
        {renderTableWithJointPolicies()}
        {renderTableWithTotal()}
        {renderSubmitIAC()}
      </>
    );
  }

  return null;
}

Products.propTypes = {
  policiesDetails: PropTypes.shape({
    clientPolicies: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      index: PropTypes.number,
      fundOrPolicyNum: PropTypes.string,
      fundValue: PropTypes.number,
      inceptionDate: PropTypes.string,
      pensionType: PropTypes.string,
      policyStatus: PropTypes.string,
      productName: PropTypes.string,
      recommendationType: PropTypes.string,
      productProvider: PropTypes.string,
      topUp: PropTypes.string,
      type: PropTypes.string,
      sourceOfFunds: PropTypes.string,
      iacPercent: PropTypes.string,
      iacValue: PropTypes.string,
      dataGroup: PropTypes.string,
      totalIACPercent: PropTypes.string,
      totalIAC: PropTypes.string,
      internalAndExternal: PropTypes.oneOf([InternalAndExternal.INTERNAL, InternalAndExternal.EXTERNAL]),
      internalPolicyKey: PropTypes.string,
      internalPolicyValue: PropTypes.number
    })),
    partnerPolicies: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      index: PropTypes.number,
      fundOrPolicyNum: PropTypes.string,
      fundValue: PropTypes.number,
      inceptionDate: PropTypes.string,
      pensionType: PropTypes.string,
      policyStatus: PropTypes.string,
      productName: PropTypes.string,
      recommendationType: PropTypes.string,
      productProvider: PropTypes.string,
      topUp: PropTypes.string,
      type: PropTypes.string,
      sourceOfFunds: PropTypes.string,
      iacPercent: PropTypes.string,
      iacValue: PropTypes.string,
      dataGroup: PropTypes.string,
      totalIACPercent: PropTypes.string,
      totalIAC: PropTypes.string,
      internalAndExternal: PropTypes.oneOf([InternalAndExternal.INTERNAL, InternalAndExternal.EXTERNAL]),
      internalPolicyKey: PropTypes.string,
      internalPolicyValue: PropTypes.number
    })),
    jointPolicies: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      index: PropTypes.number,
      fundOrPolicyNum: PropTypes.string,
      fundValue: PropTypes.number,
      inceptionDate: PropTypes.string,
      pensionType: PropTypes.string,
      policyStatus: PropTypes.string,
      productName: PropTypes.string,
      recommendationType: PropTypes.string,
      productProvider: PropTypes.string,
      topUp: PropTypes.string,
      type: PropTypes.string,
      sourceOfFunds: PropTypes.string,
      iacPercent: PropTypes.string,
      iacValue: PropTypes.string,
      dataGroup: PropTypes.string,
      totalIAC: PropTypes.string,
      internalAndExternal: PropTypes.oneOf([InternalAndExternal.INTERNAL, InternalAndExternal.EXTERNAL]),
      internalPolicyKey: PropTypes.string,
      internalPolicyValue: PropTypes.number
    })),
    summary: PropTypes.shape({
      totalIACPercent: PropTypes.string,
      totalIAC: PropTypes.string,
      totalFundValue: PropTypes.string
    })
  }),
  clientId: PropTypes.string,
  partnerId: PropTypes.string,
  clientIsMaster: PropTypes.bool,
  clientName: PropTypes.string,
  partnerName: PropTypes.string,
  setInternalExternalPolicyValues: PropTypes.func.isRequired,
  setPolicyIndexation: PropTypes.func.isRequired,
  manageNotifications: PropTypes.func.isRequired
};

export default Products;
