import Model from './Model';
import { SourceOfFund } from './SourceOfFund';

export class SourceOfFunds extends Model {
  static createSourceOfFunds (data) {
    try {
      return Array.isArray(data.choices) ? data.choices.map(sourceOfFund => (SourceOfFund.createSourceOfFund(sourceOfFund))) : [];
    } catch (e) {
      console.error(e);
    }
  }
}
