import { ClientDetails } from './ClientDetails';

export class ClientDetailsRetriever {
  static createClientDetails (clientInfo, oacStatus) {
    try {
      const parsedClientInfo = JSON.parse(clientInfo);
      return ClientDetails.createClientDetails(
        parsedClientInfo.entity_name,
        oacStatus,
        parsedClientInfo.is_master,
        parsedClientInfo.partner_entity_id);
    } catch (e) {
      console.error(e);
    }
  }
}
