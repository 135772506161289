import Model from './Model';

export class Type extends Model {
  static createType (data) {
    try {
      const type = new Type();

      type.set('key', Array.isArray(data) ? data[0] : '');
      type.set('value', Array.isArray(data) ? data[1] : '');

      return type;
    } catch (e) {
      console.log(e);
    }
  }
}
