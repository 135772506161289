import React, { useState } from 'react';
import { Card, CardGroup, Button, Form, Row, Col, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getClientData } from '../Services/ClientService';
import {
  getProcessedPolicies,
  getProcessedData,
  getPartnerId,
  combineAllPolicies,
  getOACstatus
} from '../Utils/ClientUtils';
import { iacValueLookup, calculateFundValueBasedOnIndexation, calculateIacForMultipleRecommendations } from '../Utils/IACUtils';
import Products from './Products';
import Messages from './Messages';
import {
  addNotification,
  getMessages,
  deleteNotification,
  checkForTotals,
  addMultipleNotifications,
  deleteMultipleNotifications,
  isResponseNullOrEmptyArray
} from '../Utils/MessageUtils';
import { Action, Message } from '../Enums/MessageEnum';

const TOTAL_OAC_LIMIT = 10000;

function IACCalculator ({ configurationData }) {
  const [clientId, setClientId] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [partnerData, setPartnerData] = useState(null);
  const [clientPoliciesDetails, setClientPoliciesDetails] = useState(null);
  const [processedPolicies, setProcessedPolicies] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [oacStatus, setOacStatus] = useState('');

  const onSubmitButtonClick = (e) => {
    e.preventDefault();

    if (clientId) {
      setIsLoading(true);

      getClientData(clientId, configurationData).then(clientResponse => {
        if (!isResponseNullOrEmptyArray(clientResponse)) {
          const partnerId = getPartnerId(clientResponse);
          if (partnerId) {
            getClientData(partnerId, configurationData).then(partnerResponse => {
              if (!isResponseNullOrEmptyArray(partnerResponse)) {
                setPartnerId(partnerId);
                setPartnerData(partnerResponse);
                const oacStatus = determineOACstatus(clientResponse, partnerResponse, configurationData);
                setOacStatus(oacStatus);
                const processedPoliciesData = getProcessedPolicies(clientResponse, partnerResponse, configurationData, oacStatus);
                setProcessedPolicies(processedPoliciesData);
                const processedData = setClientDataForClientAndPartner(processedPoliciesData, clientResponse, partnerResponse, configurationData, oacStatus);
                setProcessedClientData(processedData);
                setNotifications(
                  getMessages(
                    combineAllPolicies(processedData.policiesDetails.clientPolicies, processedData.policiesDetails.partnerPolicies,
                      processedData.policiesDetails.jointPolicies), processedData.policiesDetails.summary.totalIAC));
              } else {
                clearAllClientAndPartnerData();
              }
            });
          } else {
            setPartnerData(null);
            setPartnerId(null);
            const oacStatus = determineOACstatus(clientResponse, null, configurationData);
            setOacStatus(oacStatus);
            const processedPoliciesData = getProcessedPolicies(clientResponse, null, configurationData, oacStatus);
            setProcessedPolicies(processedPoliciesData);
            calculateIacForMultipleRecommendations(processedPoliciesData.processedClientPolicies, configurationData);
            const processedData = getProcessedData(
              processedPoliciesData.processedClientPolicies, processedPoliciesData.processedPartnerPolicies,
              processedPoliciesData.processedJointPolicies, clientResponse, null, oacStatus);
            setProcessedClientData(processedData);
            setNotifications(getMessages(combineAllPolicies(processedData.policiesDetails.clientPolicies, processedData.policiesDetails.partnerPolicies, processedData.policiesDetails.jointPolicies), processedData.policiesDetails.summary.totalIAC));
          }
          setClientData(clientResponse);
        } else {
          clearAllClientAndPartnerData();
        }
        setIsLoading(false);
      });
    } else {
      alert(Message.NO_CLIENT_ID.text);
    }
  };

  const determineOACstatus = (clientResponse, partnerResponse, configurationData) => {
    if (partnerResponse) {
      const clientInfo = JSON.parse(clientResponse.data[2].body);
      if (clientInfo.is_master) {
        return getOACstatus(clientResponse.data[3].body, configurationData);
      } else {
        return getOACstatus(partnerResponse.data[3].body, configurationData);
      }
    } else {
      return getOACstatus(clientResponse.data[3].body, configurationData);
    }
  };

  const setClientDataForClientAndPartner = (processedPoliciesData, clientResponse, partnerResponse, configurationData, oacStatus) => {
    calculateIacForMultipleRecommendations(
      combineAllPolicies(
        processedPoliciesData.processedClientPolicies,
        processedPoliciesData.processedPartnerPolicies,
        processedPoliciesData.processedJointPolicies),
      configurationData);
    const processedData =
      getProcessedData(
        processedPoliciesData.processedClientPolicies, processedPoliciesData.processedPartnerPolicies,
        processedPoliciesData.processedJointPolicies, clientResponse, partnerResponse, oacStatus);
    return processedData;
  };

  const clearAllClientAndPartnerData = () => {
    setClientId('');
    setPartnerId('');
    setClientData(null);
    setPartnerData(null);
    setClientPoliciesDetails(null);
    setProcessedPolicies(null);
    setClientDetails(null);
    setPartnerDetails(null);
    setNotifications([]);
    setOacStatus('');
  };

  const setProcessedClientData = (processedData) => {
    setClientPoliciesDetails(processedData ? processedData.policiesDetails : null);
    setClientDetails(processedData ? processedData.clientDetails : null);
    setPartnerDetails(processedData ? processedData.partnerDetails : null);
  };

  const setInternalExternalPolicyValues = (internalKey, externalKey, internalValue, externalValue) => {
    const policies = Object.assign([],
      processedPolicies.processedClientPolicies.concat(processedPolicies.processedPartnerPolicies).concat(processedPolicies.processedJointPolicies));
    const internalPolicy = policies.find(policy => policy.key === internalKey);
    const externalPolicy = policies.find(policy => policy.key === externalKey);
    internalPolicy.fundValue = internalValue;
    externalPolicy.fundValue = externalValue;

    let iacValues = iacValueLookup(internalPolicy.oacStatus, internalPolicy.recommendationType, internalPolicy.sourceOfFunds, internalValue, configurationData.sourceOfFundsEnum);
    internalPolicy.iacPercent = iacValues.iacPercent;
    internalPolicy.iacValue = iacValues.iacValue;
    internalPolicy.fundValue = internalValue;
    internalPolicy.isAdditionalIacCalcNeeded = iacValues.isAdditionalIacCalcNeeded;
    internalPolicy.initialPremiumValue = internalValue;

    iacValues = iacValueLookup(externalPolicy.oacStatus, externalPolicy.recommendationType, externalPolicy.sourceOfFunds, externalValue, configurationData.sourceOfFundsEnum);
    externalPolicy.iacPercent = iacValues.iacPercent;
    externalPolicy.iacValue = iacValues.iacValue;
    externalPolicy.fundValue = externalValue;
    externalPolicy.isAdditionalIacCalcNeeded = iacValues.isAdditionalIacCalcNeeded;
    externalPolicy.initialPremiumValue = externalValue;

    const processedData = setClientDataForClientAndPartner(processedPolicies, clientData, partnerData, configurationData, oacStatus);
    setProcessedClientData(processedData);
    setNotifications(getMessages(combineAllPolicies(processedData.policiesDetails.clientPolicies, processedData.policiesDetails.partnerPolicies, processedData.policiesDetails.jointPolicies), processedData.policiesDetails.summary.totalIAC));
  };

  const setPolicyIndexation = (key, indexation) => {
    const allPolicies = Object.assign([], processedPolicies.processedClientPolicies.concat(processedPolicies.processedPartnerPolicies).concat(processedPolicies.processedJointPolicies));
    const policy = allPolicies.find(policy => policy.key === key);
    const premium = policy.initialPremiumValue;
    const value = indexation !== '' ? calculateFundValueBasedOnIndexation(premium, indexation) : premium;

    const iacValues = iacValueLookup(policy.oacStatus, policy.recommendationType, policy.sourceOfFunds, value, configurationData.sourceOfFundsEnum);
    policy.iacPercent = iacValues.iacPercent ? iacValues.iacPercent : '';
    policy.iacValue = iacValues.iacValue ? iacValues.iacValue : '';
    policy.fundValue = value;
    policy.isAdditionalIacCalcNeeded = iacValues.isAdditionalIacCalcNeeded;
    policy.isIndexed = indexation !== '';
    restoreOriginalIacValues(processedPolicies.processedClientPolicies);
    restoreOriginalIacValues(processedPolicies.processedPartnerPolicies);
    restoreOriginalIacValues(processedPolicies.processedJointPolicies);

    const processedData = setClientDataForClientAndPartner(processedPolicies, clientData, partnerData, configurationData, oacStatus);
    setProcessedClientData(processedData);
    const messages = checkForTotals(combineAllPolicies(processedData.policiesDetails.clientPolicies, processedData.policiesDetails.partnerPolicies, processedData.policiesDetails.jointPolicies));
    parseFloat(processedData.policiesDetails.summary.totalIAC) === TOTAL_OAC_LIMIT ? manageNotifications(Action.ADD, messages) : manageNotifications(Action.DELETE, messages);
  };

  const restoreOriginalIacValues = (policies) => {
    policies.forEach(policy => {
      const iacValues = iacValueLookup(policy.oacStatus, policy.recommendationType, policy.sourceOfFunds, policy.fundValue, configurationData.sourceOfFundsEnum);
      policy.iacPercent = iacValues.iacPercent ? iacValues.iacPercent : '';
      policy.iacValue = iacValues.iacValue ? iacValues.iacValue : '';
      policy.isAdditionalIacCalcNeeded = iacValues.isAdditionalIacCalcNeeded;
    });
  };

  const manageNotifications = (action, notificationsToModify) => {
    switch (action) {
      case Action.ADD:
        if (Array.isArray(notificationsToModify) && notificationsToModify.length > 0) {
          setNotifications(addMultipleNotifications(notifications, notificationsToModify));
        } else {
          setNotifications(addNotification(notifications, notificationsToModify));
        }
        break;
      case Action.DELETE:
        if (Array.isArray(notificationsToModify) && notificationsToModify.length > 1) {
          setNotifications(deleteMultipleNotifications(notifications, notificationsToModify));
        } else {
          setNotifications(deleteNotification(notifications, notificationsToModify));
        }
        break;
    }
  };

  const renderButton = () => {
    if (isLoading) {
      return (
        <Button className="button-primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          {' '}
          Loading...
        </Button>
      );
    }
    return (
      <Button type="submit" className="button-primary" onClick={onSubmitButtonClick}>
        Submit
      </Button>
    );
  };

  const renderClientDetails = () => {
    return (
        <Card>
          <Table striped borderless responsive size="sm">
            <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><b>Client Name</b></td>
              <td>{clientDetails ? clientDetails.name : ''}</td>
            </tr>
            <tr>
              <td><b>OAC Status</b></td>
              <td>{clientDetails ? clientDetails.oacStatus : ''}</td>
            </tr>
            </tbody>
          </Table>
        </Card>
    );
  };

  return (
    <>
      <CardGroup>
        <Card className="card-header">
          IAC calculator
        </Card>
        <Card className="card-header">
          Client
        </Card>
      </CardGroup>

      <CardGroup>
        <Card>
          <Card.Body>
            <Form inline>
              <Row className="justify-content-md-center">
                <Form.Group as={Col} controlId="formClientId">
                  <Form.Label>Client ID</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Enter Client ID"
                      value={clientId}
                      onChange={e => setClientId(e.target.value)}
                  />
                </Form.Group>
                <Col>
                  {renderButton()}
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {renderClientDetails()}
      </CardGroup>

      <br />
      <Products
        policiesDetails={clientPoliciesDetails}
        clientId={clientId}
        partnerId={partnerId}
        clientIsMaster={clientDetails ? clientDetails.isMaster : false}
        clientName={clientDetails ? clientDetails.name : ''}
        partnerName={partnerDetails ? partnerDetails.name : ''}
        setInternalExternalPolicyValues={setInternalExternalPolicyValues}
        setPolicyIndexation={setPolicyIndexation}
        manageNotifications={manageNotifications}
      />
      <br />
      <Messages
          notifications={notifications}
      />
    </>
  );
}

IACCalculator.propTypes = {
  configurationData: PropTypes.shape({
    tiers: PropTypes.shape({
      charges: PropTypes.arrayOf(PropTypes.number),
      limits: PropTypes.arrayOf(PropTypes.number),
      percents: PropTypes.arrayOf(PropTypes.number)
    }),
    sourceOfFunds: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })),
    sourceOfFundsEnum: PropTypes.object
  })
};

export default IACCalculator;
