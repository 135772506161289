import Model from './Model';

export class PoliciesDetails extends Model {
  static createPoliciesDetails (clientPolicies, partnerPolicies, jointPolicies, summary) {
    try {
      const policiesDetails = new PoliciesDetails();
      policiesDetails.set('clientPolicies', clientPolicies ?? '');
      policiesDetails.set('partnerPolicies', partnerPolicies ?? '');
      policiesDetails.set('jointPolicies', jointPolicies ?? '');
      policiesDetails.set('summary', summary ?? {});
      return policiesDetails;
    } catch (e) {
      console.log(e);
    }
  }
}
