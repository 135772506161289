import Model from './Model';

export class PostCalculation extends Model {
  static createPostCalculation (sourceValue, targetFieldName) {
    try {
      const postCalculation = new PostCalculation();
      postCalculation.set('sourceValue', sourceValue || 0);
      postCalculation.set('targetFieldName', targetFieldName || '');
      postCalculation.set('targetValue', 0);
      return postCalculation;
    } catch (e) {
      console.log(e);
    }
  }
}
