import Model from './Model';

export class IndexationData extends Model {
  static createIacValues (key, value) {
    try {
      const indexationData = new IndexationData();
      indexationData.set('key', key);
      indexationData.set('value', value);
      return indexationData;
    } catch (e) {
      console.log(e);
    }
  }
}
