import api from './Api.js';
import { SourceOfFunds } from '../Transforms/SourceOfFunds';
import { ConfigurationData } from '../Transforms/ConfigurationData';
import { Types } from '../Transforms/Types';
import { Message } from '../Enums/MessageEnum';
import { addCurrencyField, checkIfCurrencyField } from '../Utils/JsonUtils';

const getClientData = (clientId) => {
  const RESPONSE_500 = 'Request failed with status code 500';

  return api.get(`/client/${clientId}`)
    .catch(e => {
      console.error(e);
      if (e.message === RESPONSE_500) {
        alert(Message.CHECK_CLIENT_ID.getText(clientId));
      } else {
        alert(e);
      }
      return [];
    });
};

const sendCalculationsResult = (clientId, partnerId, policiesDetails, totalIacValue, totalIACPercent, screenshot) => {
  const requests = { batch: [] };

  addClientUpdateRequestToBatch(clientId, totalIacValue, totalIACPercent, requests);
  addPostingDocNoteRequestToBatch(clientId, requests);
  addPostingScreenshotRequestToBatch(screenshot, requests);
  addPoliciesUpdatesToBatch(clientId, policiesDetails.clientPolicies, requests);
  addPoliciesUpdatesToBatch(partnerId, policiesDetails.partnerPolicies, requests);
  addPoliciesUpdatesToBatch(clientId, policiesDetails.jointPolicies, requests);

  return api.post('/batch', requests)
    .then(() => {
      alert(Message.IAC_SUBMIT.text);
    })
    .catch(e => {
      console.error(e);
      alert(Message.IAC_ERROR.text);
    });
};

const addClientUpdateRequestToBatch = (clientId, totalIacValue, totalIACPercent, requests) => {
  requests.batch.push({
    method: 'PATCH',
    url: '/resourceful/entity/client-v4/' + clientId,
    body: {
      fields: {
        total_initial_adviser_charges: totalIacValue,
        initial_adviser_charges__: totalIACPercent
      }
    },
    rollback_batch_on_failure: true,
    omit_results_on_success: false
  });
};

const addPostingDocNoteRequestToBatch = (clientId, requests) => {
  const dateString = (new Date()).toISOString();
  const clientIdInt = parseInt(clientId);

  requests.batch.push({
    name: 'POST_note',
    url: '/resourceful/docnote-v3',
    method: 'POST',
    body: {
      entity_ids: [
        clientIdInt
      ],
      subject: 'IAC Calculator',
      doctype: 'Advice Container',
      docsubtype: 'Calculators',
      permission: 2,
      reference_date: dateString
    },
    omit_results_on_success: false,
    rollback_batch_on_failure: true
  });
};

const addPostingScreenshotRequestToBatch = (screenshot, requests) => {
  requests.batch.push({
    name: 'POST_attachment',
    url: '/resourceful/docnote/%7Bresult=POST_note:$.id%7D/attachment',
    method: 'POST',
    body: {
      mimetype: 'image/png',
      filename: 'IAC Calculation.png',
      content: {
        _val: screenshot,
        _type: 'Binary'
      }
    },
    omit_results_on_success: false,
    rollback_batch_on_failure: true
  });
};

const addPoliciesUpdatesToBatch = (clientId, policies, requests) => {
  const addedPolicies = [];

  policies.forEach(policyToAdd => {
    const policyAlreadyAdded = addedPolicies.find(addedPolicy =>
      addedPolicy.index === policyToAdd.index && addedPolicy.dataGroup === policyToAdd.dataGroup);

    if (policyAlreadyAdded) {
      const requestToUpdate = requests.batch[policyAlreadyAdded.requestIdx];
      if (policyToAdd.internalPolicyKey) {
        updatePostCalculationsInRequest(policyToAdd, requestToUpdate);
      } else {
        addPostCalculationsToRequest(policyToAdd, requestToUpdate);
      }
    } else {
      const request = createPolicyUpdateRequest(clientId, policyToAdd);
      addPostCalculationsToRequest(policyToAdd, request);
      requests.batch.push(request);
      addedPolicies.push({ index: policyToAdd.index, dataGroup: policyToAdd.dataGroup, requestIdx: requests.batch.length - 1 });
    }
  });
};

const createPolicyUpdateRequest = (clientId, policy) => {
  return {
    method: 'PATCH',
    url: '/resourceful/entity/client-v4/' + clientId + '/' + policy.dataGroup + '/' + policy.index,
    body: { fields: {} },
    rollback_batch_on_failure: true,
    omit_results_on_success: false
  };
};

const addPostCalculationsToRequest = (policy, request) => {
  policy.postCalculation.forEach(postCalculation => {
    checkIfCurrencyField(postCalculation.targetFieldName)
      ? request.body.fields[postCalculation.targetFieldName] = addCurrencyField(postCalculation.targetValue)
      : request.body.fields[postCalculation.targetFieldName] = postCalculation.targetValue;
  });
};

const updatePostCalculationsInRequest = (policy, request) => {
  policy.postCalculation.forEach(postCalculation => {
    if (Object.prototype.hasOwnProperty.call(request.body.fields, postCalculation.targetFieldName) &&
        request.body.fields[postCalculation.targetFieldName] != null) {
      checkIfCurrencyField(postCalculation.targetFieldName)
        ? request.body.fields[postCalculation.targetFieldName]._val.value._val += postCalculation.targetValue
        : request.body.fields[postCalculation.targetFieldName] += postCalculation.targetValue;
    } else {
      request.body.fields[postCalculation.targetFieldName] = postCalculation.targetValue;
    }
  });
};

const getConfiguration = () => {
  return api.get('/configuration')
    .then(response =>
      ConfigurationData.createConfigurationData(
        SourceOfFunds.createSourceOfFunds(extractXplanItemFromResponse(response, 0)),
        Types.createTypes(extractXplanItemFromResponse(response, 1)),
        Types.createTypes(extractXplanItemFromResponse(response, 2)),
        SourceOfFunds.createSourceOfFunds(extractXplanItemFromResponse(response, 3)),
        response.data.tiers,
        response.data.sourceOfFunds,
        response.data.reviewsForOac)
    )
    .catch(e => {
      console.error(e);
      return {};
    });
};

const extractXplanItemFromResponse = (response, index) => {
  return JSON.parse(
    Array.isArray(response.data.xplanData) && response.data.xplanData.length > 0 &&
        response.data.xplanData[index] && response.data.xplanData[index].body
      ? response.data.xplanData[index].body
      : '{}');
};

export {
  getClientData,
  getConfiguration,
  sendCalculationsResult
};
