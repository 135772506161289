export const PostCalculationTargetFieldName = {
  TRANSFER_AFTER_CHARGES: 'transfer_after_charges',
  EMPLOYEE_GROSS_AMOUNT_POST_IAC: 'employee_gross_amount_post_iac',
  EMPLOYER_REGULAR_CONTRIBUTION_POST_IAC: 'employer_regular_contribution_post_iac',
  EMPLOYEE_SINGLE_GROSS_CONTRIBUTION_POST_IAC: 'employee_single_gross_contribution_post_iac',
  EMPLOYER_SINGLE_CONTRIBUTION_POST_IAC: 'employer_single_contribution_post_iac',
  NET_UNCRYSTALLISED_TFR_AMOUNT: 'net_uncrystallised_tfr_amount',
  INITIAL_AMOUNT: 'initial_amount',
  REG_UFPLS_AC: 'reg_ufpls_ac',
  AP_AC: 'ap_ac',
  SINGLE_CONTRIBUTION_POST_IAC: 'single_contribution_post_iac',
  B_2_B_SINGLE_POST: 'b2b_single_post',
  LUMP_SUM_TOP_UP: 'lump_sum_top_up',
  TRANSFER_BEFORE_CHARGES: 'transfer_before_charges',
  B_2_B_SINGLE_BEFORE: 'b2b_single_before',
  REGULAR_CONTRIBUTION_POST_IAC: 'regular_contribution_post_iac',
  MIG_AC: 'mig_ac'
};
