import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as AlertProvider } from 'react-alert';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { Alert, options } from './Components/Alert';
import App from './App';

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={Alert} {...options}>
      <App />
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
