import { DataGroup } from '../Enums/DataGroupEnum';

const filterPolicies = (policies) => {
  return policies.filter(policy => {
    if (!policy.policyStatus) {
      return false;
    }
    switch (policy.dataGroup) {
      case DataGroup.FUND_ZA :
        return policy.policyStatus.toUpperCase() === 'RECOMMENDED' ||
            (policy.policyStatus.toUpperCase() === 'ACTIVE' && policy.topUp === '1');
      case DataGroup.ANNUITY :
        return policy.policyStatus.toUpperCase() === 'RECOMMENDED';
      case DataGroup.ASSET :
        return policy.policyStatus.toUpperCase() === 'RECOMMENDED' ||
            ((policy.policyStatus.toUpperCase() === 'EXISTING' || policy.policyStatus.toUpperCase() === 'IN FORCE') && policy.topUp === '1');
      default :
        return false;
    }
  });
};

const filterSourceOfFunds = (sourceOfFunds, sourceOfFundsEnum) => {
  try {
    if (Object.values(sourceOfFundsEnum.fundZaInternal).includes(sourceOfFunds[0])) {
      return sourceOfFundsEnum.general[0];
    } else if (Object.values(sourceOfFundsEnum.fundZaExternal).includes(sourceOfFunds[0])) {
      return sourceOfFundsEnum.general[1];
    } else {
      return sourceOfFundsEnum.general[2];
    }
  } catch (e) {
    console.log('Source of Funds item given has incorrect structure');
  }
};

const filterAnnuitySourceOfFunds = (sourceOfFunds, sourceOfFundsEnum) => {
  try {
    if (Object.values(sourceOfFundsEnum.annuityInternal).includes(sourceOfFunds[0])) {
      return sourceOfFundsEnum.general[0];
    } else if (Object.values(sourceOfFundsEnum.annuityExternal).includes(sourceOfFunds[0])) {
      return sourceOfFundsEnum.general[1];
    } else {
      return sourceOfFundsEnum.general[2];
    }
  } catch (e) {
    console.log('Source of Funds item given has incorrect structure');
  }
};

const filterAssetSourceOfFunds = (sourceOfFunds, sourceOfFundsEnum) => {
  try {
    if (Object.values(sourceOfFundsEnum.assetInternal).includes(sourceOfFunds[0])) {
      return sourceOfFundsEnum.general[0];
    } else if (Object.values(sourceOfFundsEnum.assetExternal).includes(sourceOfFunds[0])) {
      return sourceOfFundsEnum.general[1];
    } else {
      return sourceOfFundsEnum.general[2];
    }
  } catch (e) {
    console.log('Source of Funds item given has incorrect structure');
  }
};

export {
  filterPolicies,
  filterSourceOfFunds,
  filterAnnuitySourceOfFunds,
  filterAssetSourceOfFunds
};
