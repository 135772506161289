import Model from './Model';

export class ConfigurationData extends Model {
  static createConfigurationData (sourceOfFunds, annuityTypes, assetTypes, sourceOfFundsMulti, tiers, sourceOfFundsEnum, reviewsForOac) {
    try {
      const configurationData = new ConfigurationData();
      configurationData.set('sourceOfFunds', sourceOfFunds ?? []);
      configurationData.set('annuityTypes', annuityTypes ?? []);
      configurationData.set('assetTypes', assetTypes ?? []);
      configurationData.set('sourceOfFundsMulti', sourceOfFundsMulti ?? []);
      configurationData.set('tiers', tiers ?? {});
      configurationData.set('sourceOfFundsEnum', sourceOfFundsEnum ?? {});
      configurationData.set('reviewsForOac', reviewsForOac ?? {});
      return configurationData;
    } catch (e) {
      console.log(e);
    }
  }

  static checkConfigurationData (configData) {
    return configData &&
      Array.isArray(configData.sourceOfFunds) && configData.sourceOfFunds.length > 0 &&
      Array.isArray(configData.annuityTypes) && configData.annuityTypes.length > 0 &&
      Array.isArray(configData.reviewsForOac) && configData.reviewsForOac.length > 0 &&
      configData.tiers &&
      Array.isArray(configData.tiers.charges) && configData.tiers.charges.length > 0 &&
      Array.isArray(configData.tiers.limits) && configData.tiers.limits.length > 0 &&
      Array.isArray(configData.tiers.percents) && configData.tiers.percents.length > 0;
  }
}
