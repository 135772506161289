import html2canvas from 'html2canvas';

const takeScreenshot = async () => {
  const options = {
    backgroundColor: '#F1F8FA',
    foreignObjectRendering: true,
    x: 0,
    y: 0,
    scrollX: 0,
    scrollY: 0
  };

  const containers = document.getElementsByClassName('indexation-span');
  manipulateTemplate(containers, 'inline');
  const canvas = html2canvas(document.getElementById('root'), options).then(canvas => canvas.toDataURL().split(',')[1]);
  manipulateTemplate(containers, 'none');

  return canvas;
};

const manipulateTemplate = (containers, property) => {
  for (let i = 0; i < containers.length; i++) {
    containers[i].style.display = property;
  }
};

export {
  takeScreenshot
};
