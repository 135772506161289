import { RecommendationType } from '../Enums/RecommendationTypeEnum';
import { InternalAndExternal } from '../Enums/InternalAndExternalEnum';
import { Message } from '../Enums/MessageEnum';

const TOTAL_OAC_LIMIT = 10000;

const getMessages = (policies, totalIAC) => {
  const messages = [];
  const indexes = [];
  let internalAndExternalPolicyIndexes = false;
  let regularPremiumGross = false;
  let regularUfplsTerm = false;

  policies.forEach(policy => {
    if (policy.recommendationType === RecommendationType.CANNOT_DETERMINE) {
      indexes.push(policy.fundOrPolicyNum);
    }
    if (policy.internalAndExternal === InternalAndExternal.INTERNAL) {
      internalAndExternalPolicyIndexes = true;
    }
    if (policy.recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
      regularPremiumGross = true;
    }
    if (policy.recommendationType === RecommendationType.REGULAR_UFPLS_TERM) {
      regularUfplsTerm = true;
    }
  });

  if (indexes.length > 0) {
    const message = Message.CANNOT_DETERMINE;
    message.text.concat(indexes.join('\n'));
    messages.push(message);
  }
  if (internalAndExternalPolicyIndexes) {
    messages.push(Message.INTERNAL_EXTERNAL_FUND_SOURCE);
  }
  if (regularPremiumGross) {
    messages.push(Message.REGULAR_PREMIUM_GROSS_INDEXATION_REQUIRED);
  }
  if (totalIAC === TOTAL_OAC_LIMIT.toFixed(2)) {
    messages.push(Message.TOTAL_EXCEEDED);
  }
  if (regularPremiumGross && totalIAC === TOTAL_OAC_LIMIT.toFixed(2)) {
    messages.push(Message.REGULAR_PREMIUM_GROSS_TOTAL_EXCEEDED);
  }
  if (regularUfplsTerm && totalIAC === TOTAL_OAC_LIMIT.toFixed(2)) {
    messages.push(Message.AUTO_PHASED_TOTAL_EXCEEDED);
  }

  return messages;
};

const addNotification = (messages, newNotification) => {
  const newMessages = [...messages];
  if (!newMessages.some(message => message.type === newNotification.type)) {
    newMessages.push(newNotification);
  }
  return newMessages;
};

const addMultipleNotifications = (messages, newNotifications) => {
  const newMessages = [...messages];
  newNotifications.forEach(notification => {
    if (!newMessages.some(message => message.type === notification.type)) {
      newMessages.push(notification);
    }
  });
  return newMessages;
};

const deleteNotification = (messages, oldNotification) => {
  let newMessages = [...messages];
  if (newMessages.some(item => item.type === oldNotification.type)) {
    newMessages = newMessages.filter(item => item.type !== oldNotification.type);
  }
  return newMessages;
};

const deleteMultipleNotifications = (messages, oldNotifications) => {
  let newMessages = [...messages];
  oldNotifications.forEach(notification => {
    if (newMessages.some(message => message.type === notification.type)) {
      newMessages = newMessages.filter(item => item.type !== notification.type);
    }
  });
  return newMessages;
};

const checkForTotals = (policies) => {
  const messages = [];
  let regularPremiumGross = false;
  let regularUfplsTerm = false;

  policies.forEach(policy => {
    if (policy.recommendationType === RecommendationType.REGULAR_PREMIUM_GROSS) {
      regularPremiumGross = true;
    }
    if (policy.recommendationType === RecommendationType.REGULAR_UFPLS_TERM) {
      regularUfplsTerm = true;
    }
  });

  messages.push(Message.TOTAL_EXCEEDED);

  if (regularPremiumGross) {
    messages.push(Message.REGULAR_PREMIUM_GROSS_TOTAL_EXCEEDED);
  }
  if (regularUfplsTerm) {
    messages.push(Message.AUTO_PHASED_TOTAL_EXCEEDED);
  }

  return messages;
};

const isResponseNullOrEmptyArray = (response) => {
  return !response || (response && Array.isArray(response) && response.length === 0);
};

export {
  getMessages,
  addNotification,
  addMultipleNotifications,
  deleteNotification,
  deleteMultipleNotifications,
  checkForTotals,
  isResponseNullOrEmptyArray
};
