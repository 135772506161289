import { DataGroup } from '../Enums/DataGroupEnum';
import { ClientData } from '../Transforms/ClientData';
import { ClientDetailsRetriever } from '../Transforms/ClientDetailsRetriever';
import { ClientPolicies } from '../Transforms/ClientPolicies';
import { PoliciesSummary } from '../Transforms/PoliciesSummary';
import { PoliciesDetails } from '../Transforms/PoliciesDetails';
import { calculatePostCalculation, mathRound, mathFloor, mathCeil } from './IACUtils';
import { PolicyOwner } from '../Enums/PolicyOwnerEnum';
import { ClientOACstatus } from '../Enums/ClientOACstatusEnum';
import { RecommendationType } from '../Enums/RecommendationTypeEnum';

const TOTAL_OAC_LIMIT = 10000;
let totalIACPercent = 0;
let totalIACPercentCalc = 0;

const getProcessedPolicies = (clientResponse, partnerResponse, configurationData, oacStatus) => {
  if (!clientResponse ||
      !Array.isArray(clientResponse.data) ||
      (partnerResponse && !Array.isArray(partnerResponse.data))) {
    return null;
  }

  const processedClientPolicies = processClientPolicies(clientResponse, configurationData,
    isClientMaster(clientResponse) ? PolicyOwner.CLIENT : PolicyOwner.PARTNER, oacStatus);

  let processedJointPolicies = [];
  let processedPartnerPolicies = [];
  if (partnerResponse) {
    processedPartnerPolicies = processClientPolicies(partnerResponse, configurationData,
      isClientMaster(partnerResponse) ? PolicyOwner.CLIENT : PolicyOwner.PARTNER, oacStatus);
    // Because for asset and annuity kinds (only these kinds have owners) we receive from xplan the same set of policies
    // for client and partner requests so it is enough to process one of them for JOINT
    processedJointPolicies = processClientPolicies(clientResponse, configurationData, PolicyOwner.JOINT, oacStatus);
  }

  return { processedClientPolicies, processedPartnerPolicies, processedJointPolicies };
};

const getProcessedData = (processedClientPolicies, processedPartnerPolicies, processedJointPolicies, clientResponse, partnerResponse, oacStatus) => {
  const policiesDetails = PoliciesDetails.createPoliciesDetails(
    processedClientPolicies,
    processedPartnerPolicies,
    processedJointPolicies,
    getSummary(processedClientPolicies, processedPartnerPolicies, processedJointPolicies));

  const isBelowTotalOacLimit = policiesDetails.summary.totalIAC < TOTAL_OAC_LIMIT;
  calculatePostCalculation(policiesDetails.clientPolicies, isBelowTotalOacLimit, policiesDetails.summary.totalIACPercentCalc);
  calculatePostCalculation(policiesDetails.partnerPolicies, isBelowTotalOacLimit, policiesDetails.summary.totalIACPercentCalc);
  calculatePostCalculation(policiesDetails.jointPolicies, isBelowTotalOacLimit, policiesDetails.summary.totalIACPercentCalc);

  const clientDetails = ClientDetailsRetriever.createClientDetails(clientResponse.data[2].body, oacStatus);
  const partnerDetails = partnerResponse
    ? ClientDetailsRetriever.createClientDetails(partnerResponse.data[2].body, oacStatus)
    : null;

  return ClientData.createClientData(clientDetails, partnerDetails, policiesDetails);
};

const isClientMaster = (clientResponse) => {
  const parsedClientInfo = clientResponse && Array.isArray(clientResponse.data) && clientResponse.data[2].body ? JSON.parse(clientResponse.data[2].body) : null;
  return parsedClientInfo && parsedClientInfo.is_master ? parsedClientInfo.is_master : false;
};

const processClientPolicies = (clientResponse, configurationData, term, policyOwnerType, oacStatus) => {
  let processedClientPolicies = [];
  if (clientResponse) {
    const clientPoliciesToProcess = processDataGroup(clientResponse.data[0].body, DataGroup.FUND_ZA)
      .concat(processDataGroup(clientResponse.data[1].body, DataGroup.ANNUITY))
      .concat(processDataGroup(clientResponse.data[4].body, DataGroup.ASSET));
    processedClientPolicies =
      ClientPolicies.createClientPolicies(clientPoliciesToProcess, configurationData, term, policyOwnerType, oacStatus);
  }
  return processedClientPolicies;
};

const processDataGroup = (data, dataGroup) => {
  try {
    data = JSON.parse(data.length > 0 ? data : []);
    if (data.length > 0) {
      const items = [];
      data.forEach(item => {
        item.fields.data_group = dataGroup;
        items.push(item);
      });
      return items;
    } else {
      return [];
    }
  } catch (e) {
    console.log('Cannot parse JSON, incorrect request response structure');
  }
};

const combineAllPolicies = (clientPolicies, partnerPolicies, jointPolicies) => {
  let allPolicies = [];

  if (Array.isArray(clientPolicies) && clientPolicies.length >= 1) {
    allPolicies = allPolicies.concat(clientPolicies);
  }
  if (Array.isArray(partnerPolicies) && partnerPolicies.length >= 1) {
    allPolicies = allPolicies.concat(partnerPolicies);
  }
  if (Array.isArray(jointPolicies) && jointPolicies.length >= 1) {
    allPolicies = allPolicies.concat(jointPolicies);
  }

  return allPolicies;
};

const getISANotInclIACNetIacValue = (policies) => {
  let iacValue = 0;
  policies.forEach(policy => {
    if (policy.dataGroup === DataGroup.ASSET && policy.recommendationType === RecommendationType.ISA_NOT_INCL_IAC) {
      iacValue += policy.iacValue ? parseFloat(policy.iacValue) : 0;
    }
  });
  return iacValue;
};

const getSummary = (clientPolicies, partnerPolicies, jointPolicies) => {
  const allPolicies = combineAllPolicies(clientPolicies, partnerPolicies, jointPolicies);
  if (allPolicies.length < 1) {
    return PoliciesSummary.createPoliciesSummary();
  }

  let totalIAC = 0;
  allPolicies.forEach(policy => {
    totalIAC += policy.iacValue ? parseFloat(policy.iacValue) : 0;
  });

  let totalFundValue = 0;
  allPolicies.forEach(policy => {
    totalFundValue += policy.fundValue ? parseFloat(policy.fundValue) : 0;
  });

  if (totalIAC <= TOTAL_OAC_LIMIT) {
    totalFundValue += getISANotInclIACNetIacValue(allPolicies);
    totalIACPercentCalc = (totalIAC / totalFundValue) * 100;
    totalIACPercent = mathRound(totalIACPercentCalc);
    return PoliciesSummary.createPoliciesSummary(totalIACPercent.toFixed(2), totalIACPercentCalc, totalIAC.toFixed(2), '**');
  } else {
    totalIACPercentCalc = (TOTAL_OAC_LIMIT / totalFundValue) * 100;
    totalIACPercent = mathCeil(totalIACPercentCalc);
    allPolicies.forEach(policy => {
      const fundValue = parseFloat(policy.fundValue);
      if (!isNaN(fundValue) && totalFundValue > 0) {
        policy.iacValue = mathFloor(parseFloat(policy.fundValue) / totalFundValue * TOTAL_OAC_LIMIT);
      } else {
        policy.iacValue = '';
      }
      policy.iacPercent = 'NA';
    });
    totalFundValue = mathRound(totalFundValue);
    return PoliciesSummary.createPoliciesSummary(totalIACPercent.toString(), totalIACPercentCalc, TOTAL_OAC_LIMIT.toFixed(2), totalFundValue.toString());
  }
};

const getPartnerId = (clientResponse) => {
  return clientResponse && Array.isArray(clientResponse.data) ? JSON.parse(clientResponse.data[2].body).partner_entity_id : null;
};

const formatName = (clientName) => {
  if (clientName && typeof clientName === 'string') {
    const array = clientName.split(',');
    return array.length === 2 ? array[1].trim() + ' ' + array[0].trim() : array[0];
  } else {
    return '';
  }
};

const getOACstatus = (reviews, configurationData) => {
  reviews = JSON.parse(reviews);
  if (!Array.isArray(reviews) || !Array.isArray(configurationData.reviewsForOac)) {
    return ClientOACstatus.NOT_AVAILABLE;
  }

  for (let i = 0; i < reviews.length; ++i) {
    const review = reviews[i].fields;

    if (!review || !review.status) {
      return ClientOACstatus.NOT_AVAILABLE;
    }

    if (configurationData.reviewsForOac.includes(review.status)) {
      return ClientOACstatus.OAC;
    }
  }

  return ClientOACstatus.NON_OAC;
};

export {
  getProcessedPolicies,
  getProcessedData,
  getPartnerId,
  formatName,
  getSummary,
  combineAllPolicies,
  getOACstatus
};
