export const Message = {
  REGULAR_PREMIUM_GROSS_TOTAL_EXCEEDED: {
    type: 'regularPremiumGross',
    text: 'Divide IAC(£) by 60 for regular premiums'
  },
  AUTO_PHASED_TOTAL_EXCEEDED: {
    type: 'autoPhased',
    text: 'Divide IAC(£) by the term for Auto-Phased/Regular UFPLS'
  },
  REGULAR_PREMIUM_GROSS_INDEXATION_REQUIRED: {
    type: 'regularPremiumGrossIndexation',
    text: 'Please ensure correct Indexation percentage is entered'
  },
  INTERNAL_EXTERNAL_FUND_SOURCE: {
    type: 'internalExternal',
    text: 'Please ensure correct Fund Value split for internal and external'
  },
  TOTAL_EXCEEDED: {
    type: 'total',
    text: 'IAC CAP APPLIES, Use the monetary IAC for Illustrations'
  },
  CANNOT_DETERMINE: {
    type: 'cannotDetermine',
    text: 'Cannot determine recommendation type for products:\n'
  },
  IAC_SUBMIT: {
    text: 'IAC value submitted'
  },
  IAC_ERROR: {
    text: 'Error submitting IAC value. Please double check the products and try again.'
  },
  NO_RECOMMENDATION: {
    text: '\u00a0has no recommendation. Please check if you have entered the right Entity id'
  },
  NO_CLIENT_ID: {
    text: 'Enter Client ID'
  },
  CHECK_CLIENT_ID: {
    getText: (clientId) => {
      return `Client ID: ${clientId} does not exist. Please ensure you have the correct ID as per your current Ritz window.`;
    }
  }
};

export const Action = {
  ADD: 'add',
  DELETE: 'delete'
};
