import { Policy } from './Policy';
import { filterPolicies } from '../Utils/FilterUtils';

export class ClientPolicies {
  static createClientPolicies (clientPoliciesToProcess, configurationData, policiesOwnerType, oacStatus) {
    try {
      let processedClientPolicies =
        Array.isArray(clientPoliciesToProcess)
          ? clientPoliciesToProcess.flatMap(policy => (Policy.createPolicy(policy, configurationData, policiesOwnerType, oacStatus)))
          : [];

      processedClientPolicies = filterPolicies(processedClientPolicies);
      return processedClientPolicies;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
