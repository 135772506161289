export const RecommendationType = {
  ANNUITY: 'Annuity (Gross)',
  REGULAR_UFPLS_TERM: 'Auto-Phased / Regular UFPLS - Term (Gross PA)',
  REGULAR_UFPLS: 'Auto-Phased / Regular UFPLS',
  BOND: 'Bond',
  DEPENDENT_NOPMINEE_ANNUITY: 'Dependent/Nominee Drawdown/Annuity',
  FULL_DRAWDOWN: 'Full Drawdown',
  FULL_UFPLS: 'Full UFPLS',
  ISA_INCL_IAC: 'ISA incl IAC (Gross)',
  ISA_NOT_INCL_IAC: 'ISA not incl IAC (Net)',
  OEIC: 'OEIC',
  PART_DRAWDOWN: 'Part Drawdown',
  PART_UFPLS: 'Part UFPLS',
  PENSION_SWITCH: 'Pension Switch',
  PENSION_SWITCH_IMMEDIATE_VESTING: 'Pension Switch (Immediate Vesting)',
  REGULAR_PREMIUM_GROSS: 'Regular Premium (Gross PM)',
  SECONDARY_PENSION_BENEFIT: 'Secondary pension benefit incl dd to annuity',
  SINGLE_PREMIUM_PENSION: 'Single Premium - Pension',
  CANNOT_DETERMINE: 'Cannot determine'
};
