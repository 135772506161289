import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_APIGEE_BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

// Authorization, headers, interceptors, etc. here...

export default api;
