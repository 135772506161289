import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Spinner } from 'react-bootstrap';
import IACCalculator from './Components/IACCalculator';
import { getConfiguration } from './Services/ClientService';
import { ConfigurationData } from './Transforms/ConfigurationData';

export default function App () {
  const [isConfigDownloaded, setConfigDownloaded] = useState(false);
  const [configurationData, setConfigurationData] = useState(null);
  const customAlert = useAlert();

  useEffect(() => getConfigData(), []);

  const getConfigData = () => {
    getConfiguration().then(response => {
      if (ConfigurationData.checkConfigurationData(response)) {
        setConfigurationData(response);
        setConfigDownloaded(true);
      } else {
        customAlert.show('Configuration data fetching failed.', {
          header: 'Error',
          buttonText: 'Try again',
          callback: getConfigData.bind(this)
        });
      }
    });
  };

  if (!isConfigDownloaded) {
    return (
      <Spinner
          as="span"
          animation="border"
          size="large"
          role="status"
          aria-hidden="true"
      />
    );
  }
  return (
    <div className="p-5">
      <IACCalculator configurationData={configurationData}/>
    </div>
  );
}
